import { Action } from "redux";
import { ofType } from "redux-observable";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { CustomerPortalState } from "..";
import { FileUploadResponse } from "../../../../../server/services/fileService";
import {
  FrontLayerInformationItem,
  FrontLayerInformationSource,
  IFrontlayerConfigValues,
  IFrontlayerConfiguration,
  LoadingStatus,
} from "../../../../../types/NendaTypes";
import { frontlayerService } from "../../../../http/frontlayer.service";
import { organizationUnitService } from "../../../../http/organizationUnit.service";
import {
  SetNotificationError,
  SetNotificationSuccess,
} from "./notificationReducer";
import { UpdateOrganizationUnit } from "./organizationUnitReducer";

enum FRONTLAYERCONFIG_ACTIONS {
  GET_FRONTLAYER_CONFIGS = "GET_FRONTLAYER_CONFIGS",
  GET_FRONTLAYER_CONFIGS_SUCCESS = "GET_FRONTLAYER_CONFIGS_SUCCESS",
  GET_FRONTLAYER_CONFIGS_FAILURE = "GET_FRONTLAYER_CONFIGS_FAILURE",
  GET_FRONTLAYER_CONFIG = "GET_FRONTLAYER_CONFIG",
  GET_COPY_FROM_CONFIG = "GET_COPY_FROM_CONFIG",
  GET_COPY_FROM_CONFIG_SUCCESS = "GET_COPY_FROM_CONFIG_SUCCESS",
  GET_FRONTLAYER_CONFIG_SUCCESS = "GET_FRONTLAYER_CONFIG_SUCCESS",
  GET_FRONTLAYER_CONFIG_FAILURE = "GET_FRONTLAYER_CONFIG_FAILURE",
  GET_FRONTLAYER_CONFIG_BY_PREMISE_ID = "GET_FRONTLAYER_CONFIG_BY_PREMISE_ID",
  GET_INFO_ITEM_RENDER = "GET_INFO_ITEM_RENDER",
  GET_INFO_ITEM_RENDER_SUCCESS = "GET_INFO_ITEM_RENDER_SUCCESS",
  GET_INFO_ITEM_RENDER_FAILURE = "GET_INFO_ITEM_RENDER_FAILURE",
  CREATE_FRONTLAYER_CONFIG = "CREATE_FRONTLAYER_CONFIG",
  CREATE_FRONTLAYER_CONFIG_SUCCESS = "CREATE_FRONTLAYER_CONFIG_SUCCESS",
  CREATE_FRONTLAYER_CONFIG_FAILURE = "CREATE_FRONTLAYER_CONFIG_FAILURE",
  UPDATE_FRONTLAYER_CONFIG = "UPDATE_FRONTLAYER_CONFIG",
  UPDATE_FRONTLAYER_CONFIG_SUCCESS = "UPDATE_FRONTLAYER_CONFIG_SUCCESS",
  UPDATE_FRONTLAYER_CONFIG_FAILURE = "UPDATE_FRONTLAYER_CONFIG_FAILURE",
  DELETE_FRONTLAYER_CONFIG = "DELETE_FRONTLAYER_CONFIG",
  DELETE_FRONTLAYER_CONFIG_SUCCESS = "DELETE_FRONTLAYER_CONFIG_SUCCESS",
  DELETE_FRONTLAYER_CONFIG_FAILURE = "DELETE_FRONTLAYER_CONFIG_FAILURE",
  UPLOAD_IMAGE = "UPLOAD_IMAGE",
  UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS",
  UPLOAD_IMAGE_FAILURE = "UPLOAD_IMAGE_FAILURE",
  UPLOAD_IMAGE_OR_PDF = "UPLOAD_IMAGES",
  UPLOAD_IMAGE_OR_PDF_SUCCESS = "UPLOAD_IMAGES_SUCCESS",
  CLEAR_UPLOAD_RESPONSE = "CLEAR_UPLOAD_RESPONSE",
  STORE_FRONTLAYER_PREVIEW = "STORE_FRONTLAYER_PREVIEW",
  STORE_FRONTLAYER_PREVIEW_SUCCESS = "STORE_FRONTLAYER_PREVIEW_SUCCESS",
  STORE_FRONTLAYER_PREVIEW_FAILURE = "STORE_FRONTLAYER_PREVIEW_FAILURE",
  CLEAR_FRONTLAYER_PREVIEW = "CLEAR_FRONTLAYER_PREVIEW",
  CLEAR_COPY_CONFIG = "CLEAR_COPY_CONFIG",
  CLEAR_IMAGE_UPLOAD_URL = "CLEAR_IMAGE_UPLOAD_URL",
}

interface UploadImageAction
  extends Action<FRONTLAYERCONFIG_ACTIONS.UPLOAD_IMAGE> {
  premiseId: string;
  configId: string;
  image: any;
  scope: string;
  assetType: string;
  itemId?: string;
}

interface UploadImageSuccessAction
  extends Action<FRONTLAYERCONFIG_ACTIONS.UPLOAD_IMAGE_SUCCESS> {
  premiseId: string;
  configId: string;
  response: any;
  scope: string;
  assetType: string;
  itemId?: string;
}

interface UploadImageFailureAction
  extends Action<FRONTLAYERCONFIG_ACTIONS.UPLOAD_IMAGE_FAILURE> {
  error: any;
}

export enum ImageUploadTypes {
  logo = "logo",
  homeScreenBackground = "homeScreenBackground",
  radioPageBackground = "radioPageBackground",
  informationPageBackground = "informationPageBackground",
  applicationPageBackground = "applicationPageBackground",
  itemBackground = "itemBackground",
}

export interface FrontlayerConfigState {
  infoItemPreview: FrontLayerInformationItem & { markup: string };
  frontlayerConfig?: IFrontlayerConfiguration;
  copyFromConfig?: IFrontlayerConfigValues;
  isLoading: boolean;
  informationItemUploadResponse: (FileUploadResponse & { page: number }[]) | [];
  preview: {
    loadingStatus: LoadingStatus;
    link: string;
  };
  imageUploadStatus: LoadingStatus;
  imageUploadUrl: {
    url: string;
    itemId?: string;
    type: ImageUploadTypes | undefined;
  };
}

export interface FrontlayerConfigAction {
  type: FRONTLAYERCONFIG_ACTIONS;
  payload?: any;
}

export const initialFrontlayerConfigState: FrontlayerConfigState = {
  infoItemPreview: {
    content: {} as any,
    markup: "",
    sourceType: FrontLayerInformationSource.IMAGE,
    name: "",
  },
  frontlayerConfig: undefined,
  copyFromConfig: undefined,
  isLoading: false,
  informationItemUploadResponse: [],
  imageUploadStatus: LoadingStatus.IDLE,
  preview: {
    loadingStatus: LoadingStatus.IDLE,
    link: "",
  },
  imageUploadUrl: {
    url: "",
    itemId: "",
    type: undefined,
  },
};

export function GetFrontlayerConfig(
  frontlayerConfigId: string
): FrontlayerConfigAction {
  return {
    type: FRONTLAYERCONFIG_ACTIONS.GET_FRONTLAYER_CONFIG,
    payload: frontlayerConfigId,
  };
}

export function GetCopyFromConfig(
  frontlayerConfigId: string
): FrontlayerConfigAction {
  return {
    type: FRONTLAYERCONFIG_ACTIONS.GET_COPY_FROM_CONFIG,
    payload: frontlayerConfigId,
  };
}

export function GetCopyFromConfigSuccess(
  data: IFrontlayerConfiguration
): FrontlayerConfigAction {
  return {
    type: FRONTLAYERCONFIG_ACTIONS.GET_COPY_FROM_CONFIG_SUCCESS,
    payload: data,
  };
}

export function ClearCopyConfig(): FrontlayerConfigAction {
  return {
    type: FRONTLAYERCONFIG_ACTIONS.CLEAR_COPY_CONFIG,
    payload: undefined,
  };
}

function GetFrontlayerConfigSuccess(
  frontlayerConfig: IFrontlayerConfiguration
): FrontlayerConfigAction {
  return {
    type: FRONTLAYERCONFIG_ACTIONS.GET_FRONTLAYER_CONFIG_SUCCESS,
    payload: frontlayerConfig,
  };
}

function GetFrontlayerConfigFailure(error: any): FrontlayerConfigAction {
  return {
    type: FRONTLAYERCONFIG_ACTIONS.GET_FRONTLAYER_CONFIG_FAILURE,
    payload: JSON.stringify(error),
  };
}

export function StoreFrontlayerConfigPreview(
  premiseId: string,
  config: IFrontlayerConfiguration
): FrontlayerConfigAction {
  return {
    type: FRONTLAYERCONFIG_ACTIONS.STORE_FRONTLAYER_PREVIEW,
    payload: {
      premiseId,
      data: config,
    },
  };
}

const StoreFrontlayerPreviewSuccess = (payload: string) => {
  return {
    type: FRONTLAYERCONFIG_ACTIONS.STORE_FRONTLAYER_PREVIEW_SUCCESS,
    payload,
  };
};

export const GetInfoItemRender = (payload: FrontLayerInformationItem) => {
  return {
    type: FRONTLAYERCONFIG_ACTIONS.GET_INFO_ITEM_RENDER,
    payload,
  };
};

const GetInfoItemRenderSuccess = (payload: string) => {
  return {
    type: FRONTLAYERCONFIG_ACTIONS.GET_INFO_ITEM_RENDER_SUCCESS,
    payload,
  };
};

const GetInfoItemRenderFailure = (error: any) => {
  return {
    type: FRONTLAYERCONFIG_ACTIONS.GET_INFO_ITEM_RENDER_FAILURE,
    payload: JSON.stringify(error),
  };
};
export function GetFrontlayerConfigByPremiseId(
  premiseId: string
): FrontlayerConfigAction {
  return {
    type: FRONTLAYERCONFIG_ACTIONS.GET_FRONTLAYER_CONFIG_BY_PREMISE_ID,
    payload: premiseId,
  };
}

export function CreateFrontlayerConfig(
  frontlayerConfig: Partial<IFrontlayerConfiguration>,
  premiseId?: string | undefined
): FrontlayerConfigAction {
  return {
    type: FRONTLAYERCONFIG_ACTIONS.CREATE_FRONTLAYER_CONFIG,
    payload: { frontlayerConfig, premiseId },
  };
}

function CreateFrontlayerConfigSuccess(
  frontlayerConfig: IFrontlayerConfiguration
): FrontlayerConfigAction {
  return {
    type: FRONTLAYERCONFIG_ACTIONS.CREATE_FRONTLAYER_CONFIG_SUCCESS,
    payload: frontlayerConfig,
  };
}

function CreateFrontlayerConfigFailure(error: any): FrontlayerConfigAction {
  return {
    type: FRONTLAYERCONFIG_ACTIONS.CREATE_FRONTLAYER_CONFIG_FAILURE,
    payload: error,
  };
}

export function UpdateFrontlayerConfig(
  premiseId: string,
  frontlayerConfigId: string,
  data: Partial<IFrontlayerConfiguration>,
  unsetFields?: string[]
): FrontlayerConfigAction {
  return {
    type: FRONTLAYERCONFIG_ACTIONS.UPDATE_FRONTLAYER_CONFIG,
    payload: { premiseId, frontlayerConfigId, data, unsetFields },
  };
}

function UpdateFrontlayerConfigSuccess(
  frontlayerConfig: IFrontlayerConfiguration
): FrontlayerConfigAction {
  return {
    type: FRONTLAYERCONFIG_ACTIONS.UPDATE_FRONTLAYER_CONFIG_SUCCESS,
    payload: frontlayerConfig,
  };
}

function UpdateFrontlayerConfigFailure(error: any): FrontlayerConfigAction {
  return {
    type: FRONTLAYERCONFIG_ACTIONS.UPDATE_FRONTLAYER_CONFIG_FAILURE,
    payload: error,
  };
}

export function DeleteFrontlayerConfig(id: string): FrontlayerConfigAction {
  return {
    type: FRONTLAYERCONFIG_ACTIONS.DELETE_FRONTLAYER_CONFIG,
    payload: id,
  };
}

function DeleteFrontlayerConfigSuccess(
  frontlayerConfig: IFrontlayerConfiguration
): FrontlayerConfigAction {
  return {
    type: FRONTLAYERCONFIG_ACTIONS.DELETE_FRONTLAYER_CONFIG_SUCCESS,
    payload: frontlayerConfig,
  };
}

function DeleteFrontlayerConfigFailure(error: any): FrontlayerConfigAction {
  return {
    type: FRONTLAYERCONFIG_ACTIONS.DELETE_FRONTLAYER_CONFIG_FAILURE,
    payload: error,
  };
}

export function UploadImage(
  premiseId: string,
  configId: string,
  image: any,
  scope: string,
  assetType: string
): UploadImageAction {
  return {
    type: FRONTLAYERCONFIG_ACTIONS.UPLOAD_IMAGE,
    premiseId,
    configId,
    image,
    scope,
    assetType,
  };
}

export function UploadImageOrPdf(
  premiseId: string,
  file: File
): FrontlayerConfigAction {
  return {
    type: FRONTLAYERCONFIG_ACTIONS.UPLOAD_IMAGE_OR_PDF,
    payload: {
      premiseId,
      file,
    },
  };
}

export function UploadImageOrPdfSuccess(payload: any) {
  return {
    type: FRONTLAYERCONFIG_ACTIONS.UPLOAD_IMAGE_OR_PDF_SUCCESS,
    payload,
  };
}

export const ClearUploadResponse = () => {
  return {
    type: FRONTLAYERCONFIG_ACTIONS.CLEAR_UPLOAD_RESPONSE,
  };
};

export const ClearFrontlayerPreviewLink = () => {
  return {
    type: FRONTLAYERCONFIG_ACTIONS.CLEAR_FRONTLAYER_PREVIEW,
  };
};

export const ClearImageUploadUrl = () => {
  return {
    type: FRONTLAYERCONFIG_ACTIONS.CLEAR_IMAGE_UPLOAD_URL,
  };
};

export function UploadImageForItem(
  premiseId: string,
  configId: string,
  image: any,
  scope: string,
  assetType: string,
  itemId: string
): UploadImageAction {
  return {
    type: FRONTLAYERCONFIG_ACTIONS.UPLOAD_IMAGE,
    premiseId,
    configId,
    image,
    scope,
    assetType,
    itemId,
  };
}

export function UploadImageSuccess(
  premiseId: string,
  configId: string,
  response: any,
  scope: string,
  assetType: string,
  itemId?: string
): UploadImageSuccessAction {
  return {
    type: FRONTLAYERCONFIG_ACTIONS.UPLOAD_IMAGE_SUCCESS,
    response: response,
    premiseId,
    configId,
    scope,
    assetType,
    itemId,
  };
}

export function UploadImageFailure(error: any): UploadImageFailureAction {
  return {
    type: FRONTLAYERCONFIG_ACTIONS.UPLOAD_IMAGE_FAILURE,
    error: error,
  };
}

//Selectors

export const selectCopyFromConfig = (state: CustomerPortalState) => {
  return state.frontlayerConfig.copyFromConfig;
};

export const selectFrontlayerConfig = (state: CustomerPortalState) => {
  return state.frontlayerConfig.frontlayerConfig;
};

export const selectFrontlayerConfigIsLoading = (state: CustomerPortalState) => {
  return state.frontlayerConfig.isLoading;
};

export const selectFrontlayerPreviewLink = (state: CustomerPortalState) => {
  return state.frontlayerConfig.preview.link;
};

export const selectFrontlayerPreviewStatus = (state: CustomerPortalState) => {
  return state.frontlayerConfig.preview.loadingStatus;
};

export const selectFrontlayerConfigImageUploadUrl = (
  state: CustomerPortalState
) => {
  return state.frontlayerConfig.imageUploadUrl;
};

export default function frontlayerConfigReducer(
  state = initialFrontlayerConfigState,
  action: any
): FrontlayerConfigState {
  switch (action.type) {
    case FRONTLAYERCONFIG_ACTIONS.STORE_FRONTLAYER_PREVIEW:
      return {
        ...state,
        preview: {
          ...state.preview,
          loadingStatus: LoadingStatus.LOADING,
        },
      };
    case FRONTLAYERCONFIG_ACTIONS.STORE_FRONTLAYER_PREVIEW_SUCCESS:
      return {
        ...state,
        preview: {
          link: action.payload,
          loadingStatus: LoadingStatus.SUCCEEDED,
        },
      };
    case FRONTLAYERCONFIG_ACTIONS.STORE_FRONTLAYER_PREVIEW_FAILURE:
      return {
        ...state,
        preview: {
          ...state.preview,
          loadingStatus: LoadingStatus.FAILED,
        },
      };
    case FRONTLAYERCONFIG_ACTIONS.CLEAR_FRONTLAYER_PREVIEW:
      return {
        ...state,
        preview: {
          link: "",
          loadingStatus: LoadingStatus.IDLE,
        },
      };
    case FRONTLAYERCONFIG_ACTIONS.CLEAR_COPY_CONFIG:
      return {
        ...state,
        copyFromConfig: undefined,
      };
    case FRONTLAYERCONFIG_ACTIONS.GET_FRONTLAYER_CONFIGS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case FRONTLAYERCONFIG_ACTIONS.GET_COPY_FROM_CONFIG:
      return {
        ...state,
        isLoading: true,
      };
    case FRONTLAYERCONFIG_ACTIONS.GET_COPY_FROM_CONFIG_SUCCESS:
      const configValues = action.payload;
      delete configValues._id;
      delete configValues.name;
      delete configValues.company;
      delete configValues.premise;
      return {
        ...state,
        isLoading: false,
        copyFromConfig: configValues,
      };
    case FRONTLAYERCONFIG_ACTIONS.GET_FRONTLAYER_CONFIG:
      return {
        ...state,
        isLoading: true,
      };
    case FRONTLAYERCONFIG_ACTIONS.GET_FRONTLAYER_CONFIG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        frontlayerConfig: action.payload,
      };
    case FRONTLAYERCONFIG_ACTIONS.GET_FRONTLAYER_CONFIG_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case FRONTLAYERCONFIG_ACTIONS.GET_FRONTLAYER_CONFIG_BY_PREMISE_ID:
      return {
        ...state,
        isLoading: true,
      };
    case FRONTLAYERCONFIG_ACTIONS.CREATE_FRONTLAYER_CONFIG:
      return {
        ...state,
        isLoading: true,
      };
    case FRONTLAYERCONFIG_ACTIONS.CREATE_FRONTLAYER_CONFIG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        frontlayerConfig: action.payload,
      };
    case FRONTLAYERCONFIG_ACTIONS.CREATE_FRONTLAYER_CONFIG_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case FRONTLAYERCONFIG_ACTIONS.UPDATE_FRONTLAYER_CONFIG:
      return {
        ...state,
        isLoading: true,
      };
    case FRONTLAYERCONFIG_ACTIONS.UPDATE_FRONTLAYER_CONFIG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        frontlayerConfig: action.payload,
      };
    case FRONTLAYERCONFIG_ACTIONS.UPDATE_FRONTLAYER_CONFIG_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case FRONTLAYERCONFIG_ACTIONS.DELETE_FRONTLAYER_CONFIG:
      return {
        ...state,
        isLoading: true,
      };

    case FRONTLAYERCONFIG_ACTIONS.DELETE_FRONTLAYER_CONFIG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        frontlayerConfig: action.payload,
      };

    case FRONTLAYERCONFIG_ACTIONS.DELETE_FRONTLAYER_CONFIG_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case FRONTLAYERCONFIG_ACTIONS.UPLOAD_IMAGE:
      return {
        ...state,
        isLoading: true,
      };
    case FRONTLAYERCONFIG_ACTIONS.UPLOAD_IMAGE_SUCCESS:
      switch (action.assetType) {
        case "logo":
          return {
            ...state,
            isLoading: false,
            imageUploadStatus: LoadingStatus.SUCCEEDED,
            imageUploadUrl: {
              url: action.response.responseData.fileUrl,
              type: ImageUploadTypes.logo,
            },
          };
        case "homeScreenBackground":
          return {
            ...state,
            isLoading: false,
            imageUploadStatus: LoadingStatus.SUCCEEDED,
            imageUploadUrl: {
              url: action.response.responseData.fileUrl,
              type: ImageUploadTypes.homeScreenBackground,
            },
          };
        case "radioPageBackground":
          return {
            ...state,
            isLoading: false,
            imageUploadStatus: LoadingStatus.SUCCEEDED,
            imageUploadUrl: {
              url: action.response.responseData.fileUrl,
              type: ImageUploadTypes.radioPageBackground,
            },
          };
        case "informationPageBackground":
          return {
            ...state,
            isLoading: false,
            imageUploadStatus: LoadingStatus.SUCCEEDED,
            imageUploadUrl: {
              url: action.response.responseData.fileUrl,
              type: ImageUploadTypes.informationPageBackground,
            },
          };
        case "applicationPageBackground":
          return {
            ...state,
            isLoading: false,
            imageUploadStatus: LoadingStatus.SUCCEEDED,
            imageUploadUrl: {
              url: action.response.responseData.fileUrl,
              type: ImageUploadTypes.applicationPageBackground,
            },
          };
        case "itemBackground":
          return {
            ...state,
            isLoading: false,
            imageUploadStatus: LoadingStatus.SUCCEEDED,
            imageUploadUrl: {
              url: action.response.responseData.fileUrl,
              itemId: action.itemId,
              type: ImageUploadTypes.itemBackground,
            },
          };
      }
      return {
        ...state,
        isLoading: false,
      };
    case FRONTLAYERCONFIG_ACTIONS.UPLOAD_IMAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case FRONTLAYERCONFIG_ACTIONS.GET_INFO_ITEM_RENDER:
      return {
        ...state,
        isLoading: true,
      };
    case FRONTLAYERCONFIG_ACTIONS.GET_INFO_ITEM_RENDER_SUCCESS:
      return {
        ...state,
        infoItemPreview: action.payload,
        isLoading: false,
      };
    case FRONTLAYERCONFIG_ACTIONS.GET_INFO_ITEM_RENDER_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case FRONTLAYERCONFIG_ACTIONS.UPLOAD_IMAGE_OR_PDF:
      return {
        ...state,
        imageUploadStatus: LoadingStatus.LOADING,
      };
    case FRONTLAYERCONFIG_ACTIONS.UPLOAD_IMAGE_OR_PDF_SUCCESS:
      const imageUploadResponses = action.payload.map((response) => ({
        ...response.uploadResponse,
        page: response.page,
      }));
      return {
        ...state,
        imageUploadStatus: LoadingStatus.SUCCEEDED,
        informationItemUploadResponse: imageUploadResponses,
      };
    case FRONTLAYERCONFIG_ACTIONS.CLEAR_UPLOAD_RESPONSE:
      return {
        ...state,
        informationItemUploadResponse: [],
        imageUploadStatus: LoadingStatus.IDLE,
      };
    case FRONTLAYERCONFIG_ACTIONS.CLEAR_IMAGE_UPLOAD_URL:
      return {
        ...state,
        imageUploadUrl: {
          url: "",
          itemId: "",
          type: undefined,
        },
      };
    default:
      return state;
  }
}

// -- EPICS -- //

const uploadImagesAndPdfs$ = (action$: Observable<FrontlayerConfigAction>) =>
  action$.pipe(
    ofType(FRONTLAYERCONFIG_ACTIONS.UPLOAD_IMAGE_OR_PDF),
    switchMap((action) => {
      const file = action.payload.file;
      const isPdf = file.type === "application/pdf";
      if (isPdf) {
        return organizationUnitService
          .convertPdfToImage(action.payload.premiseId, file, "frontlayer")
          .pipe(
            map((a) => {
              return UploadImageOrPdfSuccess(a.images);
            }),
            catchError((error) => of(UploadImageFailure(JSON.stringify(error))))
          );
      } else {
        return organizationUnitService
          .uploadImage(action.payload.premiseId, file, "frontlayer", file.type)
          .pipe(
            map((a) => {
              return UploadImageOrPdfSuccess([
                { page: 1, uploadResponse: a.responseData },
              ]);
            }),
            catchError((error) => of(UploadImageFailure(JSON.stringify(error))))
          );
      }
    })
  );

const getFrontlayerConfig$ = (action$: Observable<FrontlayerConfigAction>) =>
  action$.pipe(
    ofType(FRONTLAYERCONFIG_ACTIONS.GET_FRONTLAYER_CONFIG),
    switchMap((action) =>
      frontlayerService.getFrontlayerConfig(action.payload).pipe(
        map((config) => GetFrontlayerConfigSuccess(config)),
        catchError((error) => of(GetFrontlayerConfigFailure(error)))
      )
    )
  );

const getFrontlayerPreview$ = (action$: Observable<FrontlayerConfigAction>) =>
  action$.pipe(
    ofType(FRONTLAYERCONFIG_ACTIONS.STORE_FRONTLAYER_PREVIEW),
    switchMap((action) =>
      frontlayerService
        .storeFrontlayerConfigPreview(
          action.payload.premiseId,
          action.payload.data
        )
        .pipe(
          map((response) => {
            return StoreFrontlayerPreviewSuccess(response.link);
          }),
          catchError((error) => of(GetFrontlayerConfigFailure(error)))
        )
    )
  );

const getInfoItemRender$ = (action$: Observable<FrontlayerConfigAction>) =>
  action$.pipe(
    ofType(FRONTLAYERCONFIG_ACTIONS.GET_INFO_ITEM_RENDER),
    switchMap((action) =>
      frontlayerService.getInfoItemRender(action.payload).pipe(
        map((renderedInfoItem) => {
          return GetInfoItemRenderSuccess(renderedInfoItem);
        }),
        catchError((error) => of(GetInfoItemRenderFailure(error)))
      )
    )
  );

const getFrontlayerConfigByPremiseId$ = (
  action$: Observable<FrontlayerConfigAction>
) =>
  action$.pipe(
    ofType(FRONTLAYERCONFIG_ACTIONS.GET_FRONTLAYER_CONFIG_BY_PREMISE_ID),
    switchMap((action) =>
      organizationUnitService.getFrontlayerConfig(action.payload).pipe(
        map((config) => GetFrontlayerConfigSuccess(config)),
        catchError((error) => of(GetFrontlayerConfigFailure(error)))
      )
    )
  );

const getCopyFrontlayerConfig$ = (
  action$: Observable<FrontlayerConfigAction>
) =>
  action$.pipe(
    ofType(FRONTLAYERCONFIG_ACTIONS.GET_COPY_FROM_CONFIG),
    switchMap((action) =>
      frontlayerService.getFrontlayerConfig(action.payload).pipe(
        map((config) => GetCopyFromConfigSuccess(config)),
        catchError((error) => of(GetFrontlayerConfigFailure(error)))
      )
    )
  );

const failure$ = (action$: Observable<FrontlayerConfigAction>) =>
  action$.pipe(
    ofType(
      FRONTLAYERCONFIG_ACTIONS.GET_FRONTLAYER_CONFIG_FAILURE,
      FRONTLAYERCONFIG_ACTIONS.CREATE_FRONTLAYER_CONFIG_FAILURE,
      FRONTLAYERCONFIG_ACTIONS.UPDATE_FRONTLAYER_CONFIG_FAILURE,
      FRONTLAYERCONFIG_ACTIONS.DELETE_FRONTLAYER_CONFIG_FAILURE,
      FRONTLAYERCONFIG_ACTIONS.UPLOAD_IMAGE_FAILURE
    ),
    map((action) => SetNotificationError(action.payload.message))
  );

const createFrontlayerConfig$ = (action$: Observable<FrontlayerConfigAction>) =>
  action$.pipe(
    ofType(FRONTLAYERCONFIG_ACTIONS.CREATE_FRONTLAYER_CONFIG),
    switchMap((action) =>
      organizationUnitService
        .createFrontlayerConfig(
          action.payload.premiseId,
          action.payload.frontlayerConfig
        )
        .pipe(
          mergeMap((config) =>
            action.payload.premiseId
              ? [
                  CreateFrontlayerConfigSuccess(config),
                  UpdateOrganizationUnit(action.payload.premiseId, {
                    frontlayerConfig: config._id,
                  }),
                  SetNotificationSuccess("Configuration updated!"),
                ]
              : [
                  CreateFrontlayerConfigSuccess(config),
                  SetNotificationSuccess("Configuration created!"),
                ]
          ),
          catchError((error) => of(CreateFrontlayerConfigFailure(error)))
        )
    )
  );

const updateFrontlayerConfig$ = (action$: Observable<FrontlayerConfigAction>) =>
  action$.pipe(
    ofType(FRONTLAYERCONFIG_ACTIONS.UPDATE_FRONTLAYER_CONFIG),
    switchMap((action) =>
      organizationUnitService
        .updateFrontlayerConfig(
          action.payload.premiseId,
          action.payload.frontlayerConfigId,
          action.payload.data as Partial<IFrontlayerConfiguration>,
          action.payload.unsetFields
        )
        .pipe(
          mergeMap((config) => {
            return [
              UpdateFrontlayerConfigSuccess(config),
              SetNotificationSuccess("Configuration updated!"),
            ];
          }),

          catchError((error) => of(UpdateFrontlayerConfigFailure(error)))
        )
    )
  );

const deleteFrontlayerConfig$ = (action$: Observable<FrontlayerConfigAction>) =>
  action$.pipe(
    ofType(FRONTLAYERCONFIG_ACTIONS.DELETE_FRONTLAYER_CONFIG),
    switchMap((action) =>
      frontlayerService.deleteFrontlayerConfig(action.payload).pipe(
        mergeMap((config) => [
          DeleteFrontlayerConfigSuccess(config),
          SetNotificationSuccess("Configuration deleted!"),
        ]),
        catchError((error) => of(DeleteFrontlayerConfigFailure(error)))
      )
    )
  );

const uploadImage$ = (action$: Observable<any>) => {
  return action$.pipe(
    ofType(FRONTLAYERCONFIG_ACTIONS.UPLOAD_IMAGE),
    switchMap((a: UploadImageAction) => {
      return organizationUnitService
        .uploadImage(a.premiseId, a.image, a.scope, a.assetType)
        .pipe(
          mergeMap((response) => {
            return [
              SetNotificationSuccess("Image uploaded"),
              UploadImageSuccess(
                a.premiseId,
                a.configId,
                response,
                a.scope,
                a.assetType,
                a.itemId
              ),
            ];
          }),
          catchError((error) => of(UploadImageFailure(error)))
        );
    })
  );
};

export const frontlayerConfigEpics = [
  getFrontlayerConfig$,
  failure$,
  getFrontlayerConfigByPremiseId$,
  createFrontlayerConfig$,
  updateFrontlayerConfig$,
  deleteFrontlayerConfig$,
  uploadImage$,
  getInfoItemRender$,
  uploadImagesAndPdfs$,
  getFrontlayerPreview$,
  getCopyFrontlayerConfig$,
];
