import { AnyAction, configureStore } from "@reduxjs/toolkit";
import { createHashHistory } from "history";
import { Epic, combineEpics, createEpicMiddleware } from "redux-observable";
import reducer from "./reducer";
import { AssetState, assetEpics } from "./reducers/assetReducer";
import { AuthState, authEpics, initialAuthState } from "./reducers/authReducer";
import {
  CompanyState,
  companyEpics,
  initialCompanyState,
} from "./reducers/companyReducer";
import {
  ContentChannelState,
  contentChannelEpics,
  initialContentChannelState,
} from "./reducers/contentChannelReducer";
import {
  CustomizationState,
  initialState as customizationInitState,
} from "./reducers/customizationReducer";
import {
  FrontlayerConfigState,
  frontlayerConfigEpics,
  initialFrontlayerConfigState,
} from "./reducers/frontlayerConfigReducer";
import { NotificationState } from "./reducers/notificationReducer";
import {
  OrganizationUnitState,
  initialOrganizationUnitState,
  organizationUnitEpics,
} from "./reducers/organizationUnitReducer";
import {
  ScreenState,
  initialScreenState,
  screenEpics,
} from "./reducers/screenReducer";
import {
  StreamChannelState,
  streamChannelEpics,
} from "./reducers/streamChannelReducer";
import {
  UserState,
  initialUsersState,
  userEpics,
} from "./reducers/userReducer";
import {
  WorkspaceState,
  initialState as initWorkspaceState,
} from "./reducers/workspaceReducer";
import {
  DailyContentPlaylistState,
  dailyContentPlaylistEpics,
} from "./reducers/dailyContentPlaylistReducer";
import {
  PromotionState,
  initialState as initialPromotionState,
  promotionEpics,
} from "./reducers/promotionReducer";
import {
  CustomerSetupState,
  customerSetupEpics,
  initialCustomerSetupState,
} from "./reducers/customerSetupReducer";
import { LoadingStatus } from "../../../../types/NendaTypes";
import {
  initialSignagePlaylistState,
  signagePlaylistEpics,
  SignagePlaylistState,
} from "./reducers/signagePlaylistReducer";

export const effectRunner = createEpicMiddleware<
  AnyAction,
  AnyAction,
  CustomerPortalState
>();

export const history = createHashHistory();

export type CustomerPortalEpic = Epic<
  AnyAction,
  AnyAction,
  CustomerPortalState
>;

export const rootEpic = combineEpics(
  ...authEpics,
  ...organizationUnitEpics,
  ...companyEpics,
  ...screenEpics,
  ...contentChannelEpics,
  ...assetEpics,
  ...frontlayerConfigEpics,
  ...streamChannelEpics,
  ...userEpics,
  ...dailyContentPlaylistEpics,
  ...promotionEpics,
  ...customerSetupEpics,
  ...signagePlaylistEpics
);

export interface CustomerPortalState {
  workspace: WorkspaceState;
  customization: CustomizationState;
  auth: AuthState;
  notification: NotificationState;
  organizationUnits: OrganizationUnitState;
  user: UserState;
  company: CompanyState;
  screen: ScreenState;
  contentChannel: ContentChannelState;
  asset: AssetState;
  dailyContentPlaylist: DailyContentPlaylistState;
  frontlayerConfig: FrontlayerConfigState;
  streamChannel: StreamChannelState;
  promotion: PromotionState;
  customerSetup: CustomerSetupState;
  digitalSignagePlaylists: SignagePlaylistState;
}

export const initPaginationState = {
  data: [],
  page: 1,
  pageSize: 0,
  filteredCount: 0,
  totalCount: 0,
  status: LoadingStatus.IDLE,
};

const initialState: CustomerPortalState = {
  workspace: initWorkspaceState,
  customization: customizationInitState,
  auth: initialAuthState,
  notification: {
    currentNotification: undefined,
  },
  organizationUnits: initialOrganizationUnitState,
  user: initialUsersState,
  company: initialCompanyState,
  screen: initialScreenState,
  contentChannel: initialContentChannelState,
  asset: {
    paginatedCompanyAssets: {
      ...initPaginationState,
    },
    paginatedPremiseAssets: {
      ...initPaginationState,
    },
    companyAssets: {},
    premiseAssets: {},
    showCreateAssetDialog: false,
  },
  dailyContentPlaylist: {
    playlists: [],
    status: LoadingStatus.IDLE,
    editPlaylist: null,
    deletePlaylist: null,
    createEditPlaylistModalIsOpen: false,
    error: {
      message: "",
      type: null,
    },
  },
  frontlayerConfig: initialFrontlayerConfigState,
  streamChannel: {
    channels: [],
    isLoading: false,
  },
  promotion: initialPromotionState,
  customerSetup: initialCustomerSetupState,
  digitalSignagePlaylists: initialSignagePlaylistState,
};

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
      immutableCheck: true,
      serializableCheck: {
        ignoredActions: ["UPLOAD_ASSET_FILE"],
        ignoredPaths: ["oidc.user"],
      },
    }).concat(effectRunner),
  preloadedState: initialState,
});

effectRunner.run(rootEpic);

export { store };
