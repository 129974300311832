// redux reducer for stream channels

import { Epic, ofType } from "redux-observable";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { CustomerPortalState } from "..";
import {
  IStreamChannel,
  NotificationType,
} from "../../../../../types/NendaTypes";
import { streamChannelService } from "../../../../http/streamChannel.service";
import {
  STREAMCHANNEL_ACTIONS,
  StreamChannelActions,
  GetStreamChannelsAction,
  UpdateStreamChannelAction,
  DeleteStreamChannelAction,
  CreateStreamChannelAction,
} from "../../../../types/redux";
import { handleError, SetNotification } from "./notificationReducer";

export interface StreamChannelState {
  channels: IStreamChannel[];
  isLoading: boolean;
}

const initialState: StreamChannelState = {
  channels: [],
  isLoading: false,
};

//action creators
export function GetStreamChannels(): GetStreamChannelsAction {
  return { type: STREAMCHANNEL_ACTIONS.GET_STREAMCHANNELS };
}

export function GetStreamChannelsSuccess(
  channels: IStreamChannel[]
): StreamChannelActions {
  return {
    type: STREAMCHANNEL_ACTIONS.GET_STREAMCHANNELS_SUCCESS,
    channels,
  };
}

export function CreateStreamChannel(
  channel: IStreamChannel
): StreamChannelActions {
  return {
    type: STREAMCHANNEL_ACTIONS.CREATE_STREAMCHANNEL,
    channel,
  };
}

export function CreateStreamChannelSuccess(
  channel: IStreamChannel
): StreamChannelActions {
  return {
    type: STREAMCHANNEL_ACTIONS.CREATE_STREAMCHANNEL_SUCCESS,
    channel,
  };
}

export function UpdateStreamChannel(
  channelId: string,
  data: Partial<IStreamChannel>
): UpdateStreamChannelAction {
  return { type: STREAMCHANNEL_ACTIONS.UPDATE_STREAMCHANNEL, channelId, data };
}

export function UpdateStreamChannelSuccess(
  channel: IStreamChannel
): StreamChannelActions {
  return {
    type: STREAMCHANNEL_ACTIONS.UPDATE_STREAMCHANNEL_SUCCESS,
    channel,
  };
}

export function DeleteStreamChannel(channelId: string): StreamChannelActions {
  return {
    type: STREAMCHANNEL_ACTIONS.DELETE_STREAMCHANNEL,
    channelId,
  };
}

export function DeleteStreamChannelSuccess(
  channelId: string
): StreamChannelActions {
  return {
    type: STREAMCHANNEL_ACTIONS.DELETE_STREAMCHANNEL_SUCCESS,
    channelId,
  };
}

// selectors
export const selectStreamChannels = (state: CustomerPortalState) =>
  state.streamChannel.channels;

export const selectStreamChannel = (
  state: CustomerPortalState,
  channelId: string
): IStreamChannel | undefined => {
  return state.streamChannel.channels.find((c) => c._id == channelId);
};

// reducer
export function streamChannelReducer(
  state = initialState,
  action: StreamChannelActions
): StreamChannelState {
  switch (action.type) {
    case STREAMCHANNEL_ACTIONS.GET_STREAMCHANNELS:
    case STREAMCHANNEL_ACTIONS.GET_STREAMCHANNEL:
    case STREAMCHANNEL_ACTIONS.CREATE_STREAMCHANNEL:
    case STREAMCHANNEL_ACTIONS.UPDATE_STREAMCHANNEL:
    case STREAMCHANNEL_ACTIONS.DELETE_STREAMCHANNEL:
      return {
        ...state,
        isLoading: true,
      };
    case STREAMCHANNEL_ACTIONS.GET_STREAMCHANNELS_SUCCESS:
      return {
        ...state,
        channels: action.channels,
        isLoading: false,
      };
    case STREAMCHANNEL_ACTIONS.GET_STREAMCHANNELS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case STREAMCHANNEL_ACTIONS.GET_STREAMCHANNEL_SUCCESS:
      return {
        ...state,
        channels: [...state.channels, action.channel],
        isLoading: false,
      };
    case STREAMCHANNEL_ACTIONS.GET_STREAMCHANNEL_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case STREAMCHANNEL_ACTIONS.CREATE_STREAMCHANNEL_SUCCESS:
      return {
        ...state,
        channels: [...state.channels, action.channel],
        isLoading: false,
      };
    case STREAMCHANNEL_ACTIONS.CREATE_STREAMCHANNEL_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case STREAMCHANNEL_ACTIONS.UPDATE_STREAMCHANNEL_SUCCESS:
      return {
        ...state,
        channels: state.channels.map((channel) =>
          channel._id === action.channel._id ? action.channel : channel
        ),
        isLoading: false,
      };
    case STREAMCHANNEL_ACTIONS.UPDATE_STREAMCHANNEL_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case STREAMCHANNEL_ACTIONS.DELETE_STREAMCHANNEL_SUCCESS:
      return {
        ...state,
        channels: state.channels.filter(
          (channel) => channel._id !== action.channelId
        ),
        isLoading: false,
      };
    case STREAMCHANNEL_ACTIONS.DELETE_STREAMCHANNEL_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}

// Epics
const getStreamChannel$: Epic = (action$) => {
  return action$.pipe(
    ofType(STREAMCHANNEL_ACTIONS.GET_STREAMCHANNELS),
    switchMap(() => {
      return streamChannelService.getChannels().pipe(
        map((channels) => {
          return GetStreamChannelsSuccess(channels);
        }),
        catchError(handleError)
      );
    })
  );
};

const createStreamChannel$: Epic = (action$) => {
  return action$.pipe(
    ofType(STREAMCHANNEL_ACTIONS.CREATE_STREAMCHANNEL),
    switchMap((a: CreateStreamChannelAction) => {
      return streamChannelService.createChannel(a.channel).pipe(
        mergeMap((channel) => {
          return [
            CreateStreamChannelSuccess(channel),
            SetNotification("Channel created!", NotificationType.SUCCESS),
          ];
        }),
        catchError(handleError)
      );
    })
  );
};

const updateStreamChannel$: Epic = (action$) => {
  return action$.pipe(
    ofType(STREAMCHANNEL_ACTIONS.UPDATE_STREAMCHANNEL),
    switchMap((a: UpdateStreamChannelAction) => {
      return streamChannelService.updateChannel(a.channelId, a.data).pipe(
        mergeMap((channel) => {
          return [
            UpdateStreamChannelSuccess(channel),
            SetNotification("Channel updated!", NotificationType.SUCCESS),
          ];
        }),
        catchError(handleError)
      );
    })
  );
};

const deleteStreamChannel$: Epic = (action$) => {
  return action$.pipe(
    ofType(STREAMCHANNEL_ACTIONS.DELETE_STREAMCHANNEL),
    switchMap((a: DeleteStreamChannelAction) => {
      return streamChannelService.deleteChannel(a.channelId).pipe(
        mergeMap((channel) => {
          return [
            DeleteStreamChannelSuccess(channel),
            SetNotification("Channel deleted!", NotificationType.SUCCESS),
          ];
        }),
        catchError(handleError)
      );
    })
  );
};

export const streamChannelEpics = [
  getStreamChannel$,
  updateStreamChannel$,
  deleteStreamChannel$,
  createStreamChannel$,
];
