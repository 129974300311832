import {
  NotificationSeverity,
  NotificationType,
} from "../../../../../types/NendaTypes";
import {
  NotificationsActions,
  NOTIFICATION_ACTIONS,
  SetNotificationAction,
} from "../../../../types/redux";
import { of } from "rxjs";

export interface NotificationState {
  currentNotification?: string;
  severity?: NotificationSeverity;
}

export const initialState: NotificationState = {
  currentNotification: undefined,
  severity: undefined,
};

export function SetNotification(
  message?: string,
  severity?: NotificationSeverity
): SetNotificationAction {
  return {
    type: NOTIFICATION_ACTIONS.SET_NOTIFICATION,
    notification: {
      message,
      bgClass: undefined,
      severity: severity || undefined,
    },
  };
}

export function SetNotificationSuccess(message?: string) {
  return SetNotification(message, NotificationType.SUCCESS);
}
export function SetNotificationError(message?: string) {
  return SetNotification(message, NotificationType.ERROR);
}
export function SetNotificationWarning(message?: string) {
  return SetNotification(message, NotificationType.WARNING);
}
export function SetNotificationInfo(message?: string) {
  return SetNotification(message, NotificationType.INFO);
}

export const ClearNotification = () => {
  return {
    type: NOTIFICATION_ACTIONS.CLEAR_NOTIFICATION,
  };
};

export const handleError = (err: any) => {
  return of(
    SetNotification(err.response ? err.response.message : err.message),
    NotificationType.ERROR
  );
};

export default function notificationReducer(
  state: NotificationState = initialState,
  action: NotificationsActions
): NotificationState {
  switch (action.type) {
    case NOTIFICATION_ACTIONS.SET_NOTIFICATION:
      return {
        ...state,
        currentNotification: action.notification && action.notification.message,
        severity: action.notification?.severity || NotificationType.DEFAULT,
      };
    case NOTIFICATION_ACTIONS.CLEAR_NOTIFICATION:
      return {
        ...state,
        currentNotification: undefined,
        severity: undefined,
      };
    default:
      return state;
  }
}
