import { createSelector } from "@reduxjs/toolkit";
import { t } from "i18next";
import { Epic, ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { CustomerPortalState } from "..";
import {
  LoadingStatus,
  ResourcePermissions,
  Status,
} from "../../../../../types/NendaTypes";
import { authService } from "../../../../http/auth.service";
import {
  AUTH_ACTIONS,
  AuthActions,
  GetPermissionsAction,
  GetPermissionsSuccessAction,
  LoginAction,
  ResetPasswordAction,
  ResetPasswordFailAction,
  ResetPasswordSuccessAction,
  SendPasswordResetLinkAction,
  SendPasswordResetLinkSuccessAction,
  SetLoginAction,
  SetLogoutAction,
} from "../../../../types/redux";
import { extractSessionTokenData } from "../../../../utils/base64";
import { RegisterOpenItem } from "./customizationReducer";
import {
  handleError,
  SetNotificationError,
  SetNotificationSuccess,
} from "./notificationReducer";

export interface AuthState {
  permissions: ResourcePermissions;
  session?: {
    sessionToken?: string;
  };
  errors: {
    login?: {
      message?: string;
    };
  };
  status?: {
    resetPassword?: LoadingStatus;
    loggingIn?: LoadingStatus;
  };
}

export const initialAuthState: AuthState = {
  permissions: {},
  session: {
    sessionToken: localStorage.getItem("sessionToken") || undefined,
  },
  errors: {
    login: {
      message: "",
    },
  },
  status: {
    resetPassword: LoadingStatus.IDLE,
    loggingIn: LoadingStatus.IDLE,
  },
};

export function Login(username, password): LoginAction {
  return { type: AUTH_ACTIONS.LOGIN, username, password };
}

function LoginFailure(error: any) {
  return { type: AUTH_ACTIONS.LOGIN_FAILURE, error };
}

export function Logout(): SetLogoutAction {
  return { type: AUTH_ACTIONS.SET_LOGOUT };
}

function SetLogin(session): SetLoginAction {
  return { type: AUTH_ACTIONS.SET_LOGIN, session };
}

export function GetPermissions(): GetPermissionsAction {
  return { type: AUTH_ACTIONS.GET_PERMISSIONS };
}

function GetPermissionsSuccess(
  permissions: ResourcePermissions
): GetPermissionsSuccessAction {
  return { type: AUTH_ACTIONS.GET_PERMISSIONS_SUCCESS, permissions };
}

export function SendPasswordResetLink(
  email: string
): SendPasswordResetLinkAction {
  return { type: AUTH_ACTIONS.SEND_PASSWORD_RESET_LINK, email };
}

function SendPasswordResetLinkSuccess(): SendPasswordResetLinkSuccessAction {
  return { type: AUTH_ACTIONS.SEND_PASSWORD_RESET_LINK_SUCCESS };
}

export function ResetPassword(
  newPassword: string,
  hash?: string
): ResetPasswordAction {
  return { type: AUTH_ACTIONS.RESET_PASSWORD, hash, newPassword };
}

function ResetPasswordSuccess(): ResetPasswordSuccessAction {
  return { type: AUTH_ACTIONS.RESET_PASSWORD_SUCCESS };
}

function ResetPasswordFail(error: any): ResetPasswordFailAction {
  return { type: AUTH_ACTIONS.RESET_PASSWORD_FAIL, error };
}

//selectors
export const selectLoggingInStatus = (
  state: CustomerPortalState
): Status | undefined => state.auth.status?.loggingIn;

export const selectUserRole = createSelector(
  (state: CustomerPortalState) => state.auth.session?.sessionToken,
  (sessionToken) => {
    if (!sessionToken) return undefined;
    const session = extractSessionTokenData(sessionToken);
    return session?.role;
  }
);

export const selectUserCompany = createSelector(
  (state: CustomerPortalState) => state.auth.session?.sessionToken,
  (sessionToken) => {
    if (!sessionToken) return undefined;
    const session = extractSessionTokenData(sessionToken);
    return session?.company || undefined;
  }
);

export const selectUserSession = createSelector(
  (state: CustomerPortalState) => state.auth.session?.sessionToken,
  (sessionToken) => {
    if (!sessionToken) return undefined;

    const session = extractSessionTokenData(sessionToken);

    return session;
  }
);

export const selectLoggedInUser = createSelector(
  (state: CustomerPortalState) => state.auth.session?.sessionToken,
  (sessionToken) => {
    if (!sessionToken) return undefined;
    const session = extractSessionTokenData(sessionToken);
    return session?.username;
  }
);

export const getPermissions = (
  state: CustomerPortalState
): ResourcePermissions => {
  return state.auth.permissions;
};

export const getResetPasswordStatus = (
  state: CustomerPortalState
): Status | undefined => {
  return state.auth.status?.resetPassword;
};

// reducer
export default function authReducer(
  state: AuthState = initialAuthState,
  action: AuthActions
): AuthState {
  switch (action.type) {
    case AUTH_ACTIONS.LOGIN:
      return {
        ...state,
        status: { ...state.status, loggingIn: LoadingStatus.LOADING },
      };
    case AUTH_ACTIONS.LOGIN_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, login: { message: action.error } },
        status: { ...state.status, loggingIn: LoadingStatus.FAILED },
      };
    case AUTH_ACTIONS.SET_LOGIN:
      localStorage.setItem("sessionToken", action.session.sessionToken);
      return {
        ...state,
        session: action.session,
        status: { ...state.status, loggingIn: LoadingStatus.IDLE },
      };
    case AUTH_ACTIONS.SET_LOGOUT:
      localStorage.removeItem("sessionToken");
      return {
        ...state,
        session: undefined,
      };
    case AUTH_ACTIONS.GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: action.permissions,
      };
    case AUTH_ACTIONS.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        status: { ...state.status, resetPassword: LoadingStatus.SUCCEEDED },
      };
    case AUTH_ACTIONS.RESET_PASSWORD_FAIL:
      return {
        ...state,
        status: { ...state.status, resetPassword: LoadingStatus.FAILED },
      };
    default:
      return state;
  }
}

// Epics
const login$: Epic = (action$) => {
  return action$.pipe(
    ofType(AUTH_ACTIONS.LOGIN),
    switchMap((a) => {
      return authService.customerLogin(a.username, a.password).pipe(
        mergeMap((session) => {
          return [SetLogin(session), RegisterOpenItem("dashboard")];
        }),
        catchError((err) => [
          LoginFailure(
            err.status === 401
              ? t("customerportal.pages.login.errors.invalid_credentials")
              : err.message
          ),
          //If the error is 401, we don't want to show it as an error, since it's a normal wrong credentials case
          //Otherwise we use the old error handling
          ...(err.status === 401 ? [] : [SetNotificationError(err.message)]),
        ])
      );
    })
  );
};

const getPermissions$: Epic = (action$) => {
  return action$.pipe(
    ofType(AUTH_ACTIONS.GET_PERMISSIONS),
    switchMap(() => {
      return authService.getPermissions().pipe(
        map((permissions) => {
          return GetPermissionsSuccess(permissions);
        }),
        catchError(handleError)
      );
    })
  );
};

const sendPasswordResetLink$: Epic = (action$) => {
  return action$.pipe(
    ofType(AUTH_ACTIONS.SEND_PASSWORD_RESET_LINK),
    switchMap((a) => {
      return authService.sendPasswordReset(a.email).pipe(
        mergeMap(() => {
          return [
            SetNotificationSuccess("Password reset request sent"),
            SendPasswordResetLinkSuccess(),
          ];
        }),
        catchError(handleError)
      );
    })
  );
};

const resetPassword$: Epic = (action$) => {
  return action$.pipe(
    ofType(AUTH_ACTIONS.RESET_PASSWORD),
    switchMap((a) => {
      if (a.hash) {
        return authService.resetPasswordUsingHash(a.newPassword, a.hash).pipe(
          mergeMap(() => [
            SetNotificationSuccess("Password has been reset"),
            ResetPasswordSuccess(),
          ]),
          catchError((err) => of(ResetPasswordFail(err)))
        );
      } else {
        return authService.resetPasswordLoggedIn(a.newPassword).pipe(
          mergeMap(() => [
            SetNotificationSuccess("Password has been reset"),
            ResetPasswordSuccess(),
          ]),
          catchError((err) => of(ResetPasswordFail(err)))
        );
      }
    })
  );
};

export const authEpics = [
  login$,
  getPermissions$,
  sendPasswordResetLink$,
  resetPassword$,
];
