import { Box } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import DefaultDialog from "../../../ui-components/dialog/dialog";
import CreateRoomsForm from "../../Premise/CreatePremise/CreateRoomsForm";
import {
  selectNavigatedPremise,
  selectNavigatedPremiseGroup,
} from "../../../store/reducers/organizationUnitReducer";
import { useSelector } from "react-redux";
import {
  LoadingStatus,
  NendaProduct,
  RoomStatus,
} from "../../../../../../types/NendaTypes";
import {
  CreateRoomSetsProps,
  CreateRoomsRangeParameters,
  createRoomsSetup,
  selectRoomsLoadingStatus,
  setRoomsLoadingStatus,
} from "../../../store/reducers/customerSetupReducer";
import { validateRooms } from "../../Premise/CreatePremise/helpers/validate";
import NendaProducts from "../../Premise/NendaProducts";
import { range } from "../../../../../utils/array";
import CircularLoadingIndicator from "../../../ui-components/loading/CircularLoadingIndicator";
import { store } from "../../../store";
import { SetNotificationSuccess } from "../../../store/reducers/notificationReducer";

interface CreateUnitFormProps {
  onClose: () => void;
  isOpen: boolean;
}

const CreateUnitForm: React.FC<CreateUnitFormProps> = ({ isOpen, onClose }) => {
  const [newRooms, setNewRooms] = useState<CreateRoomsRangeParameters[]>([
    {
      from: 0,
      to: 0,
      roomType: "",
      errors: { from: "", to: "", roomType: "" },
    },
  ]);
  const [products, setProducts] = useState<NendaProduct[]>([]);
  const premiseGroup = useSelector(selectNavigatedPremiseGroup);
  const premise = useSelector(selectNavigatedPremise);
  const loadingStatus = useSelector(selectRoomsLoadingStatus);
  const isLoading = loadingStatus === LoadingStatus.LOADING;

  useEffect(() => {
    if (loadingStatus === LoadingStatus.SUCCEEDED) {
      setNewRooms([]);
      setProducts([]);
      store.dispatch(setRoomsLoadingStatus(LoadingStatus.IDLE));
      store.dispatch(
        SetNotificationSuccess(
          t("customerportal.pages.unit_list.create_units_success") as string
        )
      );
      onClose();
    }
  }, [loadingStatus]);

  const handleRoomsChange = (rooms: CreateRoomsRangeParameters[]): void => {
    setNewRooms(rooms);
  };

  const handleProductsChange = (product: NendaProduct): void => {
    if (products.includes(product)) {
      setProducts(products.filter((p) => p !== product));
    } else {
      setProducts([...products, product]);
    }
  };

  const handleClose = ({ isConfirmed }): void => {
    if (!isConfirmed) {
      setNewRooms([]);
      onClose();
      return;
    }

    if (newRooms.length === 0) {
      return;
    }

    if (!premise || !premiseGroup) return;

    const validatedRooms = validateRooms({
      rooms: newRooms,
      products: [],
    });

    const hasErrors = validatedRooms.some((room) => {
      return Object.values(room.errors).some((error) => error);
    });

    if (hasErrors) {
      setNewRooms(validatedRooms);
      return;
    }

    const payload = newRooms.map((roomRange): CreateRoomSetsProps => {
      const from = Number(roomRange.from);
      const to = Number(roomRange.to);
      return {
        roomNumbers: range(to - from + 1, from),
        roomType: roomRange.roomType,
        status: RoomStatus.ACTIVE,
        nendaProducts: products as NendaProduct[],
      };
    });

    store.dispatch(
      createRoomsSetup({
        hotelChainId: premiseGroup._id,
        hotelId: premise._id,
        roomSets: payload,
      })
    );
  };

  if (!premise || !premiseGroup) return null;

  return (
    <DefaultDialog
      onClose={handleClose}
      open={isOpen}
      title={t("customerportal.pages.unit_list.create_units") as string}
    >
      <Box>
        {isLoading ? (
          <CircularLoadingIndicator />
        ) : (
          <>
            <NendaProducts
              availableProducts={premise?.nendaProducts || []}
              selectedProducts={products}
              onProductSelect={handleProductsChange}
            />
            <CreateRoomsForm
              roomTypes={premiseGroup?.roomTypes || []}
              rooms={newRooms}
              onRoomsChange={handleRoomsChange}
            />
          </>
        )}
      </Box>
    </DefaultDialog>
  );
};

export default CreateUnitForm;
