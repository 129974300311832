import { CheckOutlined, HourglassTop } from "@mui/icons-material";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  CompanyType,
  LoadingStatus,
  NendaProduct,
  NotificationType,
  OrganizationUnitType,
  RoomStatus,
} from "../../../../../../types/NendaTypes";
import { store } from "../../../store";
import { getCompanies } from "../../../store/reducers/companyReducer";
import {
  getPremises,
  selectPremiseGroups,
} from "../../../store/reducers/organizationUnitReducer";
import DefaultDialog from "../../../ui-components/dialog/dialog";
import CreateRoomsForm from "./CreateRoomsForm";
import GeneralForm, { generatePassword } from "./GeneralForm";
import { validateGeneralForm, validateRooms } from "./helpers/validate";
import { SetNotificationError } from "../../../store/reducers/notificationReducer";
import {
  CreateRoomSetsProps,
  CreateRoomsRangeParameters,
  createCustomerSetup,
  resetLoadingStatus,
  selectCompanyLoadingStatus,
  selectCustomerSetupIsLoading,
  selectPremiseLoadingStatus,
  selectRoomsLoadingStatus,
} from "../../../store/reducers/customerSetupReducer";
import { range } from "../../../../../utils/array";
import { selectNavigatedCompanyId } from "@client/components/CustomerPortal/store/reducers/workspaceReducer";

export interface OverlapResult {
  index: number;
  overlaps: { to: boolean; from: boolean };
}

type CreatePremiseDialogProps = {
  onClose: () => void;
  open: boolean;
};

export type ValidationErrors = {
  name: string;
  password: string;
  company: string;
  premiseGroup: string;
  products: string;
};

export const initCreateSetupErrors = {
  name: "",
  password: "",
  company: "",
  premiseGroup: "",
  products: "",
};

const SetupCustomerDialog = ({ onClose, open }: CreatePremiseDialogProps) => {
  const theme = useTheme();
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>(
    initCreateSetupErrors
  );
  const scopedCompanyId = useSelector(selectNavigatedCompanyId);
  const [daypass, setDaypass] = useState(false);
  const [premiseName, setPremiseName] = useState("");
  const [password, setPassword] = useState(generatePassword());
  const [company, setCompany] = useState("");
  const companies = useSelector(getCompanies);
  const [premiseGroup, setPremiseGroup] = useState("");
  const [products, setProducts] = useState<NendaProduct[]>([]);
  const [rooms, setRooms] = useState<CreateRoomsRangeParameters[]>([
    {
      from: 0,
      to: 0,
      roomType: "",
      errors: { from: "", to: "", roomType: "" },
    },
  ]);

  const shouldShowDaypassOption = [
    "NendaLab",
    "Hotels",
    "NendaDemo",
    "EliteHotels",
    "Partners",
  ].includes(premiseGroup);

  const companyLoadingStatus = useSelector(selectCompanyLoadingStatus);
  const premiseLoadingStatus = useSelector(selectPremiseLoadingStatus);
  const createScreensStatus = useSelector(selectRoomsLoadingStatus);

  const setupLoading = useSelector(selectCustomerSetupIsLoading);

  const companyName = company?.trim();

  const existingCompany = companies.find((c) => c.name === companyName);
  const scopedCompany = companies.find((c) => c._id === scopedCompanyId);
  const hasRooms =
    rooms.length >= 1 && rooms[0].from !== 0 && rooms[0].to !== 0;

  const premiseGroups = useSelector(selectPremiseGroups);
  const premises = useSelector(getPremises);
  const selectedPremiseGroup = premiseGroups.find(
    (pg) => pg._id === premiseGroup
  );

  const allIsSuccessful = useMemo(() => {
    if (existingCompany && !hasRooms) {
      return (
        companyLoadingStatus === LoadingStatus.SUCCEEDED &&
        premiseLoadingStatus === LoadingStatus.SUCCEEDED
      );
    } else if (existingCompany && hasRooms) {
      return (
        companyLoadingStatus === LoadingStatus.SUCCEEDED &&
        premiseLoadingStatus === LoadingStatus.SUCCEEDED &&
        createScreensStatus === LoadingStatus.SUCCEEDED
      );
    } else {
      return premiseLoadingStatus === LoadingStatus.SUCCEEDED;
    }
  }, [
    companyLoadingStatus,
    premiseLoadingStatus,
    createScreensStatus,
    existingCompany,
    hasRooms,
  ]);

  const validate = () => {
    const generalErrors = validateGeneralForm({
      premises,
      premiseName,
      password,
      company: companyName,
      products,
      premiseGroup,
    });
    const roomsWithErrors = validateRooms({ rooms, products });

    setValidationErrors(generalErrors);
    setRooms(roomsWithErrors);

    return {
      generalErrors,
      roomsWithErrors,
    };
  };

  const clearErrors = () => {
    setValidationErrors(initCreateSetupErrors);
    setRooms(
      rooms.map((room) => ({
        ...room,
        errors: { from: "", to: "", roomType: "" },
      }))
    );
  };

  const handleCreate = ({ isConfirmed }: { isConfirmed: boolean }) => {
    if (!isConfirmed) {
      clearState();
      clearErrors();
      onClose();
      return;
    }

    const errors = validate();
    const hasGeneralErrors = Object.values(errors.generalErrors).some(
      (e) => e !== ""
    );
    const hasRoomErrors = errors.roomsWithErrors.some(
      (room) =>
        room.errors.from !== "" ||
        room.errors.to !== "" ||
        room.errors.roomType !== ""
    );
    if (hasGeneralErrors || hasRoomErrors) {
      const generalErrors = Object.values(errors.generalErrors).filter(
        (e) => e !== ""
      );
      const roomErrors = errors.roomsWithErrors.map((room) => room.errors);
      const generalErrorString = generalErrors.join(", ");
      const roomErrorString = roomErrors.map((e) =>
        Object.values(e)
          .filter((v) => v !== "")
          .join(", ")
      );
      store.dispatch(
        SetNotificationError(generalErrorString + ", " + roomErrorString)
      );
      return;
    }

    const companyPayload = {
      name: existingCompany ? "" : companyName,
      _id: existingCompany?._id || undefined,
      type: CompanyType.CUSTOMER,
    };

    const roomsPayload: CreateRoomSetsProps[] = hasRooms
      ? rooms.map((roomRange) => {
          const from = Number(roomRange.from);
          const to = Number(roomRange.to);
          return {
            roomNumbers: range(to - from + 1, from),
            roomType: roomRange.roomType,
            status: RoomStatus.ACTIVE,
            nendaProducts: products as NendaProduct[],
          };
        })
      : [];

    const premisePayload = {
      name: premiseName,
      nendaProducts: products as NendaProduct[],
      parent: premiseGroup,
      password,
      type: OrganizationUnitType.HOTEL,
      showNonPurchasedContent: daypass,
    };

    store.dispatch(
      createCustomerSetup({
        company: companyPayload,
        premise: premisePayload,
        rooms: roomsPayload,
      })
    );
  };

  const clearState = useCallback(() => {
    setPremiseName("");
    setPassword(generatePassword);
    if (!scopedCompany) {
      setCompany("");
    }
    setPremiseGroup("");
    setProducts([]);
    setRooms([
      {
        from: 0,
        to: 0,
        roomType: "",
        errors: { from: "", to: "", roomType: "" },
      },
    ]);
  }, [scopedCompany]);

  useEffect(() => {
    if (allIsSuccessful) {
      clearState();
      onClose();
      store.dispatch(resetLoadingStatus());
    }
  }, [allIsSuccessful, clearState, onClose]);

  useEffect(() => {
    if (scopedCompany && !company) {
      setCompany(scopedCompany.name);
    }
  }, [scopedCompany, company]);

  const roomTypes = selectedPremiseGroup?.roomTypes || [];

  return (
    <DefaultDialog
      onClose={handleCreate}
      width="40rem"
      open={open}
      title={t("customerportal.pages.premises.create_modal.title") as string}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          position: "relative",
        }}
      >
        {setupLoading && (
          <LoadingScreen
            companyLoadingStatus={companyLoadingStatus}
            premiseLoadingStatus={premiseLoadingStatus}
            screenLoadingStatus={createScreensStatus}
          />
        )}
        <Box>
          <Typography
            variant="caption"
            sx={{
              color: theme.palette.grey[600],
              textTransform: "uppercase",
              fontSize: "0.75rem",
            }}
          >
            {t(
              "customerportal.pages.premises.create_modal.general_information"
            )}
          </Typography>
          <GeneralForm
            premiseName={premiseName}
            setPremiseName={setPremiseName}
            password={password}
            setPassword={setPassword}
            company={company}
            setCompany={setCompany}
            premiseGroup={premiseGroup}
            setPremiseGroup={setPremiseGroup}
            premiseGroups={premiseGroups}
            validationErrors={validationErrors}
            products={products}
            setProducts={setProducts}
            daypass={daypass}
            setDaypass={setDaypass}
            showDaypass={shouldShowDaypassOption}
            scopedCompany={scopedCompany}
          />
        </Box>
        <>
          {selectedPremiseGroup && (
            <>
              <Typography
                variant="caption"
                sx={{
                  color: theme.palette.grey[600],
                  textTransform: "uppercase",
                  fontSize: "0.75rem",
                }}
              >
                {t("customerportal.pages.premises.create_modal.rooms")}
              </Typography>
              <CreateRoomsForm
                roomTypes={roomTypes}
                rooms={rooms}
                onRoomsChange={setRooms}
              />
            </>
          )}
        </>
      </Box>
    </DefaultDialog>
  );
};

const LoadingScreen = ({
  screenLoadingStatus,
  companyLoadingStatus,
  premiseLoadingStatus,
}: {
  screenLoadingStatus: LoadingStatus;
  companyLoadingStatus: LoadingStatus;
  premiseLoadingStatus: LoadingStatus;
}) => {
  const theme = useTheme();
  const statusIcon = (status: LoadingStatus) => {
    switch (status) {
      case LoadingStatus.LOADING:
        return <CircularProgress size={16} />;
      case LoadingStatus.SUCCEEDED:
        return <CheckOutlined sx={{ color: theme.palette.success.main }} />;
      case LoadingStatus.FAILED:
        return <CheckOutlined sx={{ color: theme.palette.error.main }} />;
      default:
        return <HourglassTop sx={{ color: theme.palette.primary.light }} />;
    }
  };

  return (
    <Box
      sx={{
        position: "absolute",
        zIndex: 100,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          justifyContent: "space-between",
          alignItems: "center",
          width: "50%",
          animation: "pulse 2s ease infinite",
          "@keyframes pulse": {
            "0%": {
              opacity: 0.5,
            },
            "50%": {
              opacity: 1,
            },
            "100%": {
              opacity: 0.5,
            },
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography variant="body2">{`Creating company... `}</Typography>
          {statusIcon(companyLoadingStatus)}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography variant="body2">{`Creating premise... `}</Typography>
          {statusIcon(premiseLoadingStatus)}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography variant="body2">{`Creating screens... `}</Typography>
          {statusIcon(screenLoadingStatus)}
        </Box>
      </Box>
    </Box>
  );
};

export default SetupCustomerDialog;
