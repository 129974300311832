import { Alert, Button, Stack, Typography } from "@mui/material";
import { ScheduledCommand } from "@nendaTypes/NendaTypes";
import { t } from "i18next";
import { addScheduledCommand } from "../../store/reducers/screenReducer";
import { store } from "../../store";

interface CommandsProps {
  screenId: string;
}

const Commands: React.FC<CommandsProps> = ({ screenId }) => {
  const handleCommandButtonClick = (command: ScheduledCommand) => {
    store.dispatch(
      addScheduledCommand({
        screenId: screenId,
        command,
      })
    );
  };

  return (
    <Stack gap={1}>
      <Typography variant="h6">
        {t("customerportal.pages.unit.commands.title")}
      </Typography>
      <Alert severity="info">
        {t("customerportal.pages.unit.commands.info")}
      </Alert>
      {Object.values(ScheduledCommand).map((command) => (
        <Button
          key={command}
          variant="contained"
          onClick={() => handleCommandButtonClick(command)}
        >
          {t(`customerportal.pages.unit.commands.${command.toLowerCase()}`)}
        </Button>
      ))}
    </Stack>
  );
};

export default Commands;
