import {
  Button,
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
  useTheme,
} from "@mui/material";
import { Provider, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import App from "../CustomerPortal/App";
import LoggedOutLayout from "../CustomerPortal/LoggedOutLayout";
import { CustomerProtectedRoute } from "../CustomerPortal/components/CustomerProtectedRoute";
import { CustomerPortalState, store } from "../CustomerPortal/store";
import themeOptions from "../CustomerPortal/theme/theme";
import { SnackbarProvider, useSnackbar } from "notistack";
import { useEffect } from "react";
import {
  ClearNotification,
  SetNotification,
} from "../CustomerPortal/store/reducers/notificationReducer";
import { NotificationType } from "@nendaTypes/NendaTypes";

const theme = createTheme(themeOptions);
const loggedOutRoutes = [
  {
    path: "login",
  },
  {
    path: "forgotpassword",
  },
  {
    path: "resetpassword/:hash",
  },
];

const CustomerPortal = () => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const notification = useSelector(
    (state: CustomerPortalState) => state.notification
  );

  const notificationColor = (severity: NotificationType) => {
    switch (severity) {
      case NotificationType.ERROR:
        return theme.palette.error.main;
      case NotificationType.INFO:
        return theme.palette.info.main;
      case NotificationType.SUCCESS:
        return theme.palette.success.main;
      case NotificationType.WARNING:
        return theme.palette.warning.main;
      default:
        return theme.palette.primary.light;
    }
  };

  useEffect(() => {
    if (notification.currentNotification) {
      enqueueSnackbar(notification.currentNotification, {
        variant: notification.severity,
        preventDuplicate: true,
        onExited: () => {
          store.dispatch(ClearNotification());
        },
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        style: {
          backgroundColor: notification.severity
            ? notificationColor(notification.severity)
            : undefined,
        },
      });
    }
  }, [notification, enqueueSnackbar]);

  return (
    <>
      <Routes>
        <Route
          path="/*"
          element={<CustomerProtectedRoute path="/*" element={<App />} />}
        />
        {loggedOutRoutes.map((route) => (
          <Route
            path={route.path}
            key={route.path}
            element={<LoggedOutLayout />}
          />
        ))}
      </Routes>
    </>
  );
};

export const CustomerPortalIndex = () => {
  return (
    <Provider store={store}>
      <SnackbarProvider maxSnack={3}>
        <StyledEngineProvider injectFirst>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <CustomerPortal />
          </ThemeProvider>
        </StyledEngineProvider>
      </SnackbarProvider>
    </Provider>
  );
};
