import { UserSession } from "../../types/NendaTypes";
import { Buffer } from "buffer";

export const decodeBase64 = (base64String) => {
  const decodedString = Buffer.from(base64String, "base64").toString("utf-8");
  return decodedString;
};

export const extractSessionTokenData = (
  sessionToken: string
): UserSession | undefined => {
  if (sessionToken === "") {
    return undefined;
  }

  const decodedSession = decodeJwtPayload(sessionToken);

  const session = {
    role: decodedSession.type,
    expires: decodedSession.exp,
    username: decodedSession.user,
    company: decodedSession.company,
    premises: decodedSession.premises,
    issuedAt: decodedSession.iat,
  };
  return session;
};

const base64UrlToBase64 = (base64Url: string): string => {
  // Replace URL-safe characters with standard Base64 characters
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  // Pad with '=' to make the length a multiple of 4
  while (base64.length % 4) {
    base64 += "=";
  }
  return base64;
};
/**
 * Decodes the payload of a JWT token.
 * @param token The full JWT token.
 */
export const decodeJwtPayload = (token: string): any => {
  const parts = token.split(".");
  if (parts.length < 2) {
    throw new Error("Invalid token");
  }
  const payloadBase64Url = parts[1];
  const payloadBase64 = base64UrlToBase64(payloadBase64Url);
  const decodedPayload = Buffer.from(payloadBase64, "base64").toString("utf-8");
  return JSON.parse(decodedPayload);
};
