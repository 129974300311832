import { Palette } from "@mui/material/styles";

export const paletteLight: Partial<Palette> = {
  mode: "light",
  common: {
    white: "#fff",
    black: "#000",
  },
  primary: {
    light: "#557AFF", //LightBlue
    main: "#323DBD", //Medium Blue
    dark: "#0F007A", //Dark Blue
    contrastText: "#fff",
    gray: "#F7F7F7",
  },
  secondary: {
    light: "#1DE9B6", //LightGreen
    main: "#FF6633", //Orange
    dark: "#070329", //Dark Purple
    contrastText: "#fff",
    gray: "#F7F7F7",
  },
  background: {
    paper: "rgb(255,255,255)", //White
    default: "#D7D5E2",
  },
  text: {
    primary: "#0F007A !important", //Dark Blue
    secondary: "#FFF", //White
    disabled: "#727272", //Grey
  },
  error: {
    main: "#f95757",
    light: "#FF000",
    dark: "#FF000",
    contrastText: "#ffeeed",
    gray: "#F7F7F7",
  },
  success: {
    main: "#4daf64",
    light: "#4cfc75",
    dark: "#4cfc75",
    contrastText: "#fff",
    gray: "#F7F7F7",
  },
  gradient: {
    darkBlue: "linear-gradient(38.96deg, #0A0532 -10.1%, #53489D 100.46%)",
    lightBlue:
      "linear-gradient(90deg, #199EF9 15.09%, rgba(21, 96, 199, 0.69) 100%)",
    lightPurple:
      "linear-gradient(270deg, #B4BBFB 0.01%, rgba(180, 187, 251, 0.54) 98.6%)",
  },
  transparent: {
    blue: "linear-gradient(56.69deg, rgba(26, 0, 234, 0.06) 0%, rgba(26, 0, 235, 0.07) 100%)",
    darkBlue: "rgba(50, 61, 189, 0.8)",
    softBlue:
      "linear-gradient(56.69deg, rgba(26, 0, 234, 0.03) 0%, rgba(26, 0, 235, 0.04) 100%)",
    purple:
      "linear-gradient(57deg, rgba(26, 0, 234, 0.02) 0%, rgba(26, 0, 235, 0.03) 100%)",
    softPurple: "rgba(26, 0, 234, 0.04)",
    hardPurple: "rgba(26, 0, 234, 0.5)",
    green:
      "linear-gradient(34.73deg, rgba(3, 255, 192, 0.5) 0%, rgba(5, 250, 189, 0.205) 100%)",
    white: "rgba(255, 255, 255, 0.6)",
    softWhite: "rgba(255, 255, 255, 0.3)",
    orange: "rgba(255, 103, 52, 0.2)",
    mediumOrange: "rgba(255, 103, 52, 0.5)",
    black: "rgba(0, 0, 0, 0.35)",
  },
};
