import {
  Backdrop,
  Box,
  CircularProgress,
  Fade,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import { t } from "i18next";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ForgotPasswordForm } from "../Forms/ForgotPasswordForm";
import LoginForm from "../Forms/LoginForm";
import { ResetPasswordForm } from "../Forms/ResetPasswordForm";
import NendaPortalLogo from "./assets/images/svg/nenda_portal_logo.svg";
import { isLoggedIn } from "./components/CustomerProtectedRoute";
import { selectLoggingInStatus } from "./store/reducers/authReducer";
import { default as bgImage } from "./assets/images/login-bg.jpg";
import LanguageSwitcher from "./components/LanguageSwitcher";

const LoggedOutLayout = () => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const userIsLoggedIn = isLoggedIn();

  useEffect(() => {
    if (userIsLoggedIn) {
      navigate("/");
    }
  }, [userIsLoggedIn]);

  const content = pageContent(pathname, theme);
  const loginStatus = useSelector(selectLoggingInStatus);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        minHeight: "100vh",
        display: "flex",
      }}
    >
      {/* Sidebar */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
          background: theme.palette.gradient.darkBlue,
          maxWidth: "30%",
          [theme.breakpoints.down("lg")]: {
            maxWidth: "50%",
          },
          [theme.breakpoints.down("md")]: {
            maxWidth: "100%",
          },
        }}
      >
        <Fade in={true}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            {/* Logo */}
            <Box
              sx={{
                width: "100%",
                padding: "4rem 2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <NendaPortalLogo />
            </Box>
            {/* Form */}
            <Box
              sx={{
                px: 5,
                [theme.breakpoints.up("xl")]: {
                  px: 10,
                },
              }}
            >
              {content?.form}
            </Box>
            {/* Links */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "1rem",
                padding: "1rem",
                color: theme.palette.text.secondary,
              }}
            >
              <LanguageSwitcher loginPage />
              <Box sx={{display: "flex", gap: 2}}>
                <Link
                  href="https://www.nenda.com"
                  underline="none"
                  color="inherit"
                  sx={{
                    "&:hover": {
                      textDecoration: "none",
                    },
                  }}
                >
                  <Typography variant="caption">
                    {t("customerportal.pages.login.links.homepage")}
                  </Typography>
                </Link>
                <Link
                  href="mailto:support@nenda.com"
                  underline="none"
                  color="inherit"
                  sx={{
                    "&:hover": {
                      textDecoration: "none",
                    },
                  }}
                >
                  <Typography variant="caption">
                    {t("customerportal.pages.login.links.contact_support")}
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Box>
      {/* Main Content */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flex: 1,
          padding: "2rem 4rem",
          color: theme.palette.text.secondary,
          backgroundImage: `linear-gradient(
              to right,
              rgba(0, 0, 0, 0.7),
              rgba(117, 19, 93, 0)
            ),
            url(${bgImage})`,
          backgroundSize: "cover",
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        }}
      >
        <Fade in={true} timeout={800}>
          <Box>{content?.text}</Box>
        </Fade>
      </Box>
      {/* Loading Backdrop */}
      {loginStatus === "loading" && (
        <Backdrop
          sx={{
            color: theme.palette.common.white,
            zIndex: theme.zIndex.drawer + 1,
          }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Box>
  );
};

export default LoggedOutLayout;

// Helper function to generate page-specific content
const pageContent = (pathname: string, theme: any) => {
  if (!pathname) return null;

  if (pathname.startsWith("/resetpassword")) {
    return {
      form: (
        <ResetPasswordForm
          title={t("customerportal.pages.reset_password.title") as string}
          description={
            t("customerportal.pages.reset_password.description") as string
          }
        />
      ),
      text: (
        <>
          <Typography variant="h1">
            {t("customerportal.pages.reset_password.hero.row_1")}
          </Typography>
          <Typography variant="h1">
            {t("customerportal.pages.reset_password.hero.row_2")}
          </Typography>
        </>
      ),
    };
  }

  switch (pathname) {
    case "/login":
      return {
        form: <LoginForm />,
        text: (
          <>
            <Typography variant="h1">
              {t("customerportal.pages.login.hero.row_1")}
            </Typography>
            <Typography variant="h1">
              {t("customerportal.pages.login.hero.row_2")}{" "}
              <span style={{ color: theme.palette.secondary.light }}>
                {t("customerportal.pages.login.hero.highlight")}
              </span>
            </Typography>
          </>
        ),
      };
    case "/forgotpassword":
      return {
        form: <ForgotPasswordForm />,
        text: (
          <>
            <Typography variant="h1">
              {t("customerportal.pages.forgot_password.hero.row_1")}
            </Typography>
            <Typography variant="h1">
              {t("customerportal.pages.forgot_password.hero.row_2")}
            </Typography>
          </>
        ),
      };
    default:
      return null;
  }
};
