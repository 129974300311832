import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { catchError, filter, mergeMap, switchMap } from "rxjs/operators";
import { CustomerPortalEpic, CustomerPortalState } from "..";
import {
  IUser,
  LoadingStatus,
  NotificationType,
  SimpleUser,
} from "../../../../../types/NendaTypes";
import { IListResponse } from "../../../../../types/RequestTypes";
import { companyService } from "../../../../http/companyService";
import { CreateUserPayload, userService } from "../../../../http/user.service";
import { SetNotification } from "./notificationReducer";
import { of } from "rxjs";

export interface UserState {
  users: IListResponse<SimpleUser> & { loadingStatus?: "idle" | "success" };
  loadingStatus: {
    create: LoadingStatus;
    update: LoadingStatus;
    delete: LoadingStatus;
  };
  deleteUser: {
    isLoading: boolean;
    showConfirmationDialog: boolean;
    userToDelete: SimpleUser["_id"] | null;
    userDeleted: SimpleUser["_id"];
  };
}

export const initialUsersState: UserState = {
  users: {
    data: [],
    page: 0,
    pageSize: 0,
    filteredCount: 0,
    totalCount: 0,
    loadingStatus: LoadingStatus.IDLE,
  },
  loadingStatus: {
    create: LoadingStatus.IDLE,
    update: LoadingStatus.IDLE,
    delete: LoadingStatus.IDLE,
  },
  deleteUser: {
    isLoading: false,
    showConfirmationDialog: false,
    userToDelete: null,
    userDeleted: false,
  },
};

const usersSlice = createSlice({
  name: "users",
  initialState: initialUsersState,
  reducers: {
    getUsers() {},
    getUsersByCompany(_state, _action: PayloadAction<string>) {},
    getUsersSuccess(state, action: PayloadAction<IListResponse<IUser>>) {
      state.users = { ...action.payload, loadingStatus: "success" };
    },
    createUser(state, _action: PayloadAction<CreateUserPayload>) {
      state.loadingStatus.create = LoadingStatus.LOADING;
    },
    createUserSuccess(state, action: PayloadAction<SimpleUser>) {
      state.loadingStatus.create = LoadingStatus.SUCCEEDED;
      state.users.data.push(action.payload);
    },
    creatUserFailure(state) {
      state.loadingStatus.create = LoadingStatus.FAILED;
    },
    updateUser(state, _action: PayloadAction<Partial<IUser>>) {
      state.loadingStatus.update = LoadingStatus.LOADING;
    },
    updateUserSuccess(state, action: PayloadAction<SimpleUser>) {
      state.loadingStatus.update = LoadingStatus.SUCCEEDED;
      state.users.data = state.users.data.map((u) => {
        if (u._id === action.payload._id) {
          return action.payload;
        }
        return u;
      });
    },
    updateUserFailure(state) {
      state.loadingStatus.update = LoadingStatus.FAILED;
    },
    deleteUser(state, _action: PayloadAction<string>) {
      state.loadingStatus.delete = LoadingStatus.LOADING;
    },
    deleteUserFailure(state) {
      state.loadingStatus.delete = LoadingStatus.FAILED;
    },
    deleteUserSuccess(state, action: PayloadAction<SimpleUser>) {
      state.users.data = state.users.data.filter(
        (u) => u._id !== action.payload._id
      );
      state.loadingStatus.delete = LoadingStatus.SUCCEEDED;
    },
    clearLoadingStatus(state) {
      state.loadingStatus = {
        create: LoadingStatus.IDLE,
        update: LoadingStatus.IDLE,
        delete: LoadingStatus.IDLE,
      };
    },
  },
});

// Selectors
export const selectUsers = (
  state: CustomerPortalState
): IListResponse<SimpleUser> => {
  return state.user.users;
};

export const selectShowDeleteUserConfirmation = (
  state: CustomerPortalState
) => {
  return state.user.deleteUser.showConfirmationDialog;
};

export const selectCreateUserSucceeded = (state: CustomerPortalState) => {
  return state.user.loadingStatus.create === LoadingStatus.SUCCEEDED;
};
export const selectUpdateUserSucceeded = (state: CustomerPortalState) => {
  return state.user.loadingStatus.update === LoadingStatus.SUCCEEDED;
};
export const selectCreateUserLoading = (state: CustomerPortalState) => {
  return state.user.loadingStatus.create === LoadingStatus.LOADING;
};
export const selectUpdateUserLoading = (state: CustomerPortalState) => {
  return state.user.loadingStatus.update === LoadingStatus.LOADING;
};

// Epics
const getUsers$: CustomerPortalEpic = (action$) =>
  action$.pipe(
    filter(usersSlice.actions.getUsers.match),
    switchMap(() => {
      return userService.getUsers().pipe(
        mergeMap((response) => {
          return [getUsersSuccess(response)];
        }),
        catchError((err) => of(SetNotification(err, NotificationType.ERROR)))
      );
    })
  );

const getUsersByCompany$: CustomerPortalEpic = (action$) => {
  return action$.pipe(
    filter(usersSlice.actions.getUsersByCompany.match),
    switchMap((a) => {
      return companyService.getUsers(a.payload).pipe(
        mergeMap((response) => {
          return [getUsersSuccess(response.data)];
        }),
        catchError((err) =>
          of(SetNotification(err.message, NotificationType.ERROR))
        )
      );
    })
  );
};

const updateUser$: CustomerPortalEpic = (action$) => {
  return action$.pipe(
    filter(usersSlice.actions.updateUser.match),
    switchMap((a) => {
      return userService.updateUser(a.payload._id, a.payload).pipe(
        mergeMap((response) => {
          return [updateUserSuccess(response)];
        }),
        catchError((err) => {
          return [
            updateUserFailure(),
            SetNotification(
              "Failed to update user: " + err.message,
              NotificationType.ERROR
            ),
          ];
        })
      );
    })
  );
};

const createUser$: CustomerPortalEpic = (action$) => {
  return action$.pipe(
    filter(usersSlice.actions.createUser.match),
    switchMap((a) => {
      return userService.createUser(a.payload).pipe(
        mergeMap((response) => {
          return [
            createUserSuccess(response),
            SetNotification(
              `User ${response.email} created`,
              NotificationType.SUCCESS
            ),
          ];
        }),
        catchError((err) => {
          if (err.status === 409) {
            return [
              creatUserFailure(),
              SetNotification(
                "User with this email already exists",
                NotificationType.ERROR
              ),
            ];
          } else {
            return [
              creatUserFailure(),
              SetNotification(
                "Failed to create user: " + err.message,
                NotificationType.ERROR
              ),
            ];
          }
        })
      );
    })
  );
};

const deleteUser$: CustomerPortalEpic = (action$) => {
  return action$.pipe(
    filter(usersSlice.actions.deleteUser.match),
    switchMap((a) => {
      return userService.deleteUser(a.payload).pipe(
        mergeMap((response) => {
          return [
            deleteUserSuccess(response),
            SetNotification(
              `User ${response.email} deleted`,
              NotificationType.SUCCESS
            ),
          ];
        }),
        catchError((err) => {
          return [
            deleteUserFailure(),
            SetNotification(
              "Failed to delete user: " + err.message,
              NotificationType.ERROR
            ),
          ];
        })
      );
    })
  );
};

export const userEpics = [
  getUsers$,
  getUsersByCompany$,
  createUser$,
  deleteUser$,
  updateUser$,
];
export const {
  getUsers,
  getUsersByCompany,
  getUsersSuccess,
  createUser,
  createUserSuccess,
  creatUserFailure,
  deleteUser,
  deleteUserSuccess,
  updateUserSuccess,
  updateUserFailure,
  updateUser,
  deleteUserFailure,
  clearLoadingStatus,
} = usersSlice.actions;

export default usersSlice.reducer;
