import {
  IContentOverride,
  ScheduleContentRefType,
  ScheduledCommand,
} from "../../types/NendaTypes";
import { httpClient, Service } from "./client";

class RoomService extends Service {
  getRoom(id: string) {
    return httpClient.get(`/organizationunits/rooms/${id}`, this.authHeader());
  }

  getPurchases(roomId: string) {
    return httpClient.get(
      `/organizationunits/rooms/${roomId}/purchases`,
      this.authHeader()
    );
  }

  setContentOverride = (screenId: string, body: IContentOverride) => {
    return httpClient.post(
      `/organizationunits/rooms/${screenId}/signage/contentoverride`,
      body,
      this.authHeader()
    );
  };

  unsetContentOverride = (screenId: string) => {
    return httpClient.post(
      `/organizationunits/rooms/${screenId}/signage/unset-contentoverride`,
      {},
      this.authHeader()
    );
  };

  batchSetContentOverride = (body: IContentOverride[]) => {
    return httpClient.put(
      `/organizationunits/rooms/signage/contentoverride`,
      body,
      this.authHeader()
    );
  };
  batchUnsetContentOverride = (body: { screenIds: string[] }) => {
    return httpClient.post(
      `/organizationunits/rooms/signage/unset-contentoverride`,
      body,
      this.authHeader()
    );
  };

  sync(roomId: string) {
    return httpClient.post(
      `/organizationunits/rooms/${roomId}/sync`,
      {},
      this.authHeader()
    );
  }

  getTimeline(id: string, query: { from: string; to: string; type: string }) {
    const queryString = `?from=${query?.from}&to=${query?.to}&type=${query?.type}`;
    return httpClient.get(
      `/organizationunits/rooms/${id}/schedule/timeline${queryString}`,
      this.authHeader()
    );
  }
  batchGetTimeline(ids, query: { from: string; to: string; type?: string }) {
    const idsString = ids.map((id: string) => `ids=${id}`).join("&");
    const queryString = `?${idsString + "&from=" + query?.from}&to=${
      query?.to
    }`;
    const type = query?.type ? `&type=${query?.type}` : "";

    return httpClient.get(
      `/organizationunits/rooms/schedule/timelines${queryString + type}`,
      this.authHeader()
    );
  }

  batchPutWeeklyScheduleForScreens(payload: any) {
    return httpClient.put(
      `/organizationunits/rooms/signage/weeklycontentschedule`,
      payload,
      this.authHeader()
    );
  }
  batchSetDefaultContentForScreens({
    contentId,
    contentRefType,
    screenIds,
  }: {
    contentId: string;
    contentRefType: ScheduleContentRefType;
    screenIds: string[];
  }) {
    return httpClient.post(
      `/organizationunits/rooms/signage/defaultContent`,
      { contentId, contentRefType, screenIds },
      this.authHeader()
    );
  }

  addScheduledCommand(roomId: string, command: ScheduledCommand) {
    return httpClient.post(
      `/organizationunits/rooms/${roomId}/signage/scheduledcommand`,
      { command: command },
      this.authHeader()
    );
  }
}

export const roomService = new RoomService();
