import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Autocomplete,
  Box,
  Button,
  capitalize,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import passwordGenerator from "generate-password-browser";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Company,
  Resource,
  Role,
  Scope,
} from "../../../../../types/NendaTypes";
import { getUserRole } from "../../../../redux/auth.redux";
import { getCompanies } from "../../store/reducers/companyReducer";
import { getPremises } from "../../store/reducers/organizationUnitReducer";
import DropdDownPaper from "../../ui-components/DropdownPaper";
import InputSelect from "../../ui-components/input/InputSelect";
import TextInput from "../../ui-components/input/TextInput";
import PermissionsGate from "../PermissionGate";
import { t } from "i18next";
import { getCompanyIdByPremise } from "@client/utils/company";
import * as yup from "yup";
import { isEmail } from "@shared/stringUtils";

export const isPremiseSelectVisible = (role) => {
  if ([Role.PremiseUser, Role.PremiseAdmin].includes(role)) {
    return true;
  }
  return false;
};
type CreateEditUserProps = {
  setFieldValue: (field: string, value: any) => void;
  email: string;
  password: string;
  shouldSendEmail: boolean;
  company: string | null;
  role: Role | undefined;
  selectedPremiseIds: string[];
  isEdit?: boolean;
  isSubmittable?: (boolean) => void;
};

interface FieldErrors {
  email?: string;
  password?: string;
  role?: string;
  company?: string;
  selectedPremises?: string;
  [key: string]: string | undefined;
}

const notCheckedIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;



export const CreateEditUserForm = React.memo(
  ({
    setFieldValue,
    email,
    password,
    shouldSendEmail,
    company,
    role,
    selectedPremiseIds,
    isEdit,
    isSubmittable,
  }: CreateEditUserProps) => {
    const userRole = useSelector(getUserRole);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});

    const createUserSchema = yup.object().shape({
      role: yup
        .string()
        .required(
          t("customerportal.pages.users.modal_errors.role") ?? "Role is required"
        ),
      company: yup
        .string()
        .required(
          t("customerportal.pages.users.modal_errors.company") ??
            "Company is required"
        ),
      email: yup
        .string()
        .required(
          t("customerportal.pages.users.modal_errors.email_required") ??
            "Email is required"
        )
        .test(
          "is-valid-email",
          t("customerportal.pages.users.modal_errors.email_invalid") ??
            "Invalid email address",
          (value) => isEmail(value || "")
        ),
      password: yup
        .string()
        .required(
          t("customerportal.pages.users.modal_errors.password_required") ??
            "Password is required"
        )
        .min(
          8,
          t("customerportal.pages.users.modal_errors.password_min") ??
            "Password must be at least 8 characters"
        ),
      selectedPremises: yup
        .array()
        .min(
          1,
          t("customerportal.pages.users.modal_errors.premise") ??
            "At least one premise must be selected"
        ),
    });
    
    const togglePassword = useCallback((event) => {
      event.preventDefault();
      setIsPasswordVisible((prev) => !prev);
    }, []);

    const companies = useSelector(getCompanies);
    const premises = useSelector(getPremises);

    const companyPremises = useCallback(
      (companyId: string) =>
        premises.filter(
          (p) => p.company && getCompanyIdByPremise(p) === companyId
        ),
      [premises]
    );

    const generatePassword = () => {
      const pwd = passwordGenerator.generate({
        excludeSimilarCharacters: true,
      });
      setFieldValue("password", pwd);
    };

    const handlePasswordChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setFieldValue("password", event.target.value);

      if (fieldErrors.password && event.target.value.length >= 8) {
        setFieldErrors((prevErrors) => {
          const { password, ...rest } = prevErrors;
          return rest;
        });
      }
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue("email", event.target.value);
      if (
        fieldErrors.email &&
        isEmail(event.target.value)
      ) {
        setFieldErrors((prevErrors) => {
          const { email, ...rest } = prevErrors;
          return rest;
        });
      }
    };

    const handlePremiseSelect = useCallback(
      (_event, value) => {
        setFieldValue(
          "selectedPremises",
          value.map((premise) => premise._id)
        );
      },
      [setFieldValue]
    );

    const handleShouldSendEmailChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setFieldValue("shouldSendEmail", event.target.checked);
    };

    const handleRoleChange = (event: SelectChangeEvent) => {
      setFieldValue("role", event.target.value as Role);
    };

    const handleCompanyChange = (event: SelectChangeEvent) => {
      setFieldValue("company", event.target.value as string);
    };

    let selectableRoles = Object.values(Role);
    if (userRole !== Role.Admin) {
      selectableRoles = selectableRoles.filter((role) => role !== Role.Admin);
    }

    const selectedPremises = useMemo(() => {
      return companyPremises(company || "").filter((premise) =>
        selectedPremiseIds.includes(premise._id)
      );
    }, [selectedPremiseIds, company, companyPremises]);

    useEffect(() => {
      const premisesValid =
        !isPremiseSelectVisible(role) ||
        (selectedPremises && selectedPremises.length > 0);

      const isValid =
        email &&
        company &&
        role &&
        password.length >= 8 &&
        isEmail(email) &&
        premisesValid;

      isSubmittable && isSubmittable(isValid);
      
    }, [email, password, selectedPremises, role, company ]);

    const validateField = async (fieldName: string, value: any) => {
      try {
        await createUserSchema.validateAt(fieldName, { [fieldName]: value });
        setFieldErrors((prevErrors) => {
          const { [fieldName]: removedError, ...rest } = prevErrors;
          return rest;
        });
      } catch (error: any) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [fieldName]: error.message,
        }));
      }
    };

    return (
      <Box>
        <Box mt={3}>
          <Stack spacing={2}>
            <PermissionsGate
              restriction={{
                resource: Resource.User,
                scopes: [Scope.CanAdministrate],
              }}
            >
              <React.Fragment>
                <InputSelect
                  id="role-select"
                  value={role || ""}
                  title={t("customerportal.pages.users.role")}
                  variant="standard"
                  name="role"
                  onChange={handleRoleChange}
                  onBlur={() => validateField("role", role)}
                  error={!!fieldErrors.role}
                  helperText={fieldErrors.role}
                >
                  {selectableRoles.map((role, index) => (
                    <MenuItem key={index} value={role}>
                      {capitalize(role)}
                    </MenuItem>
                  ))}
                </InputSelect>
                <InputSelect
                  id="company-select"
                  value={company || ""}
                  title={t("customerportal.pages.users.company")}
                  variant="standard"
                  name="company"
                  onChange={handleCompanyChange}
                  onBlur={() => validateField("company", company)}
                  error={!!fieldErrors.company}
                  helperText={fieldErrors.company}
                >
                  {companies.map((com: Company) => (
                    <MenuItem key={com._id} value={com._id}>
                      {com.name}
                    </MenuItem>
                  ))}
                </InputSelect>
                {isPremiseSelectVisible(role) && (
                  <Box>
                    <Typography
                      sx={{ paddingLeft: "0.3rem", fontSize: "0.7rem" }}
                    >
                      {t("customerportal.pages.users.premises")}
                    </Typography>
                    <Autocomplete
                      multiple
                      autoComplete={false}
                      id="premise"
                      options={companyPremises(company || "")}
                      value={selectedPremises}
                      onChange={handlePremiseSelect}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.name}
                      PaperComponent={DropdDownPaper}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={notCheckedIcon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextInput
                          variant="standard"
                          {...params}
                          onBlur={() =>
                            validateField("selectedPremises", selectedPremises)
                          }
                          error={!!fieldErrors.selectedPremises}
                          helperText={fieldErrors.selectedPremises}
                        />
                      )}
                    />
                  </Box>
                )}
              </React.Fragment>
            </PermissionsGate>
            <TextInput
              disabled={isEdit}
              name="email"
              variant="standard"
              label={t("customerportal.pages.users.email")}
              value={email}
              autoComplete="off"
              onChange={handleEmailChange}
              onBlur={() => validateField("email", email)}
              error={!!fieldErrors.email}
              helperText={fieldErrors.email}
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <TextInput
                variant="standard"
                id="password"
                type={isPasswordVisible ? "text" : "password"}
                value={password}
                autoComplete="new-password"
                onChange={handlePasswordChange}
                onBlur={() => validateField("password", password)}
                error={!!fieldErrors.password}
                helperText={fieldErrors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={togglePassword}
                        onMouseDown={togglePassword}
                        edge="end"
                      >
                        {isPasswordVisible ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label={t("customerportal.pages.users.password")}
              />
              <Button
                sx={{
                  p: 1,
                  pt: 0,
                  pb: 0,
                  width: "fit-content",
                  justifyContent: "flex-start",
                }}
                variant="text"
                id="password"
                onClick={generatePassword}
              >
                {t("customerportal.pages.users.generate_password")}
              </Button>
              <FormControlLabel
                sx={{ width: "fit-content", justifyContent: "flex-start" }}
                control={
                  <Checkbox
                    name="shouldSendEmail"
                    checked={shouldSendEmail}
                    onChange={handleShouldSendEmailChange}
                  />
                }
                label={t("customerportal.pages.users.send_email")}
              />
            </Box>
          </Stack>
        </Box>
      </Box>
    );
  }
);

CreateEditUserForm.displayName = "CreateEditUserForm";
