import { Box, CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import constants from "@shared/constants";
import { format, startOfDay } from "date-fns";
import { t } from "i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { DayOfWeek, Hour, Minute } from "../../../../../../types/HelperTypes";
import {
  IAsset,
  IPromotionPlaylistItem,
  IRoom,
  ISignagePromotion,
  NotificationType,
  ScheduleSlotType,
} from "../../../../../../types/NendaTypes";
import { CustomerPortalState, store } from "../../../store";

import {
  createCompanyPromotion,
  createPremisePromotion,
  updateCompanyPromotion,
  updatePremisePromotion,
} from "../../../store/reducers/promotionReducer";
import { selectNavigationScope } from "../../../store/reducers/workspaceReducer";
import DefaultDialog from "../../../ui-components/dialog/dialog";
import { FOREVER_END_DATE } from "./components/DateRangeSelect";
import { days } from "./components/DaySelect";
import StepperForm, { ScheduleRule } from "./components/StepperForm";
import {
  TogglePromotionModal,
  UnSetEditPromotionId,
} from "@client/components/CustomerPortal/store/reducers/customizationReducer";
import { SetNotificationError } from "@client/components/CustomerPortal/store/reducers/notificationReducer";

export type MediaBooking = {
  id: string;
  duration: number | undefined;
};

export type CreateUpdateObject = Partial<
  ISignagePromotion & {
    schedule: { date: { start: string; end: string } };
  }
>;

export type CustomDialogAction = {
  label: string;
  onClick: () => void;
  disabled: boolean;
  variant?: "text" | "contained" | "outlined";
  visible?: boolean;
};

export type CreatePromotionFormState = {
  promotionId?: string;
  companyId?: string;
  premiseId?: string;
  name: string;
  type: ScheduleSlotType;
  media: MediaBooking[] | [];
  schedule: {
    time: {
      start: Date | undefined;
      end: Date | undefined;
    };
    date: {
      start: Date | undefined;
      end: Date | undefined;
    };
    days: string[];
  };
  screens: string[];
  isHighPriority?: boolean;
  appliedRules: ScheduleRule[];
};

export const intialPromotionState: CreatePromotionFormState = {
  name: "",
  type: ScheduleSlotType.SIGNAGE,
  media: [],
  schedule: {
    time: {
      start: startOfDay(new Date()),
      end: startOfDay(new Date()),
    },
    date: {
      start: new Date(),
      end: new Date(),
    },
    days: days.map((d) => d.label),
  },
  screens: [],
  isHighPriority: false,
  appliedRules: [],
};
type CreateEditPromotionProps = {
  screens: IRoom[];
  assets: IAsset[];
  promotion?: ISignagePromotion;
  isLoading: boolean;
};

const CreateEditPromotionModal = ({
  screens,
  assets,
  promotion,
  isLoading,
}: CreateEditPromotionProps) => {
  const theme = useTheme();
  const isOpen = useSelector(
    (state: CustomerPortalState) =>
      state.customization.promotions.promotionModalIsOpen
  );
  const isSmallDesktop = useMediaQuery(theme.breakpoints.down("xl"));
  const scope = useSelector(selectNavigationScope);
  const companyId = scope.company;
  const premiseId = scope.premise;

  const [promotionState, setPromotionState] =
    useState<CreatePromotionFormState>({
      ...intialPromotionState,
    });

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);
  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  const handleClose = () => {
    setActiveStep(0);
    setPromotionState({ ...intialPromotionState });
    store.dispatch(UnSetEditPromotionId());
    store.dispatch(TogglePromotionModal());
  };

  if (promotion?._id !== promotionState.promotionId) {
    let startDate = new Date();
    let endDate = new Date();
    const startTime = new Date();
    const endTime = new Date();

    if (promotion?.schedule.date.start) {
      startDate = new Date(promotion?.schedule.date.start);
    }
    if (promotion?.schedule.date.end) {
      endDate = new Date(promotion?.schedule.date.end);
    }

    startTime.setHours(promotion?.schedule.time.start.hour || 0);
    startTime.setMinutes(promotion?.schedule.time.start.minute || 0);
    endTime.setHours(promotion?.schedule.time.end.hour || 0);
    endTime.setMinutes(promotion?.schedule.time.end.minute || 0);

    const isTimeApplied =
      startTime &&
      endTime &&
      (startTime.getHours() !== 0 ||
        startTime.getMinutes() !== 0 ||
        endTime.getHours() !== 0 ||
        endTime.getMinutes() !== 0);

    const isDateApplied =
      endDate &&
      endDate.setHours(0, 0, 0, 0) <
        new Date(FOREVER_END_DATE).setHours(0, 0, 0, 0);

    const appliedRules: ScheduleRule[] = [];
    if (isDateApplied) appliedRules.push("date");
    if (isTimeApplied) appliedRules.push("time");

    setPromotionState({
      ...promotionState,
      appliedRules,
      promotionId: promotion?._id,
      companyId: promotion?.company,
      premiseId: promotion?.premise,
      name: promotion?.name || "",
      screens: screens.map((s) => s._id) || [],
      schedule: {
        time: {
          start: startTime,
          end: endTime,
        },
        date: {
          start: startDate,
          end: endDate,
        },
        days:
          promotion?.schedule.days.map((d) => {
            return days.find((day) => day.dayIndex === d)?.label || "";
          }) || [],
      },
      media:
        promotion?.playlist?.map((pi: IPromotionPlaylistItem) => {
          return {
            id: pi.asset,
            duration: pi.durationInSeconds,
            sortOrder: pi.sortOrder,
          };
        }) || [],
    });
  }

  const handleScheduleRuleApplied = (
    rule: ScheduleRule,
    isApplied: boolean
  ) => {
    if (isApplied) {
      setPromotionState((prev) => ({
        ...prev,
        appliedRules: [...prev.appliedRules, rule],
      }));
    } else {
      setPromotionState((prev) => ({
        ...prev,
        appliedRules: prev.appliedRules.filter((r) => r !== rule),
      }));
    }
  };

  const { SIGNAGE_SLOT_DURATION: maxDuration } = constants.Signage();
  const minDuration = 5;

  const scheduleRulesSelected =
    promotionState.schedule.date.start &&
    promotionState.schedule.date.end &&
    promotionState.schedule.days.length > 0;

  const onlyOneAssetSelected = promotionState.media.length === 1;

  const mediaDurationValid =
    onlyOneAssetSelected &&
    promotionState.media[0].duration !== undefined &&
    promotionState.media[0].duration >= minDuration &&
    promotionState.media[0].duration <= maxDuration;

  const progressConditions = useMemo(
    () => ({
      //Content
      0: true,
      1: onlyOneAssetSelected && mediaDurationValid,
      //Date-time
      2: !!scheduleRulesSelected,
      //Screens
      3: true,
      //Preview
      4: true,
    }),
    [onlyOneAssetSelected, mediaDurationValid, scheduleRulesSelected]
  );
  const allStepsPriorToCurrentAreValid = useCallback(
    (step: number) => {
      for (let i = 0; i < step; i++) {
        if (!progressConditions[i]) return false;
      }
      return true;
    },
    [progressConditions]
  );

  useEffect(() => {
    if (activeStep != 1 || !onlyOneAssetSelected) {
      return;
    }
    if (!mediaDurationValid) {
      store.dispatch(
        SetNotificationError(
          t("customerportal.pages.promotions.video_duration", {
            minDuration,
            maxDuration,
          }) || ""
        )
      );
    }
  }, [activeStep, maxDuration, mediaDurationValid, onlyOneAssetSelected]);

  const isValidStepClick = useCallback(
    (step: number) => {
      const allPriorStepsAreValid = allStepsPriorToCurrentAreValid(step);
      switch (step) {
        case 0:
          return progressConditions[step];
        case 1:
        case 2:
        case 3:
        case 4:
          return progressConditions[step] && allPriorStepsAreValid;
        default:
          return false;
      }
    },
    [allStepsPriorToCurrentAreValid, progressConditions]
  );

  const createUpdateBookingObject: CreateUpdateObject = useMemo(() => {
    return {
      name:
        promotionState.name ||
        `${
          assets?.find((a) => a?._id === promotionState.media[0]?.id)?.name ||
          ""
        } - ${format(Date.now(), "yyyy-MM-dd")}`,
      company: companyId,
      premise: premiseId || undefined,
      isHighPriority: promotionState.isHighPriority,
      schedule: {
        time: {
          start: {
            hour: 0,
            minute: 0,
          },
          end: {
            hour: 0,
            minute: 0,
          },
        },
        date: {
          start: format(Date.now(), "yyyy-MM-dd") as any,
          end: format(FOREVER_END_DATE, "yyyy-MM-dd") as any,
        },
        days: promotionState.schedule.days.map(
          (d) => days.find((day) => day.label === d)?.dayIndex
        ) as unknown as DayOfWeek[],
      },
      playlist: promotionState.media.map((m, i) => {
        return {
          asset: m.id,
          durationInSeconds: Number(Number(m.duration).toFixed(2)),
          sortOrder: i,
        };
      }),
    };
  }, [assets, companyId, premiseId, promotionState]);

  if (
    promotionState.appliedRules.includes("date") &&
    createUpdateBookingObject.schedule
  ) {
    createUpdateBookingObject.schedule.date.start =
      (promotionState.schedule.date?.start &&
        format(promotionState.schedule.date?.start as Date, "yyyy-MM-dd")) ||
      ("" as any);
    createUpdateBookingObject.schedule.date.end =
      (promotionState.schedule.date?.end &&
        format(promotionState.schedule.date?.end as Date, "yyyy-MM-dd")) ||
      ("" as any);
  }

  if (
    promotionState.schedule.time.start &&
    promotionState.schedule.time.end &&
    createUpdateBookingObject.schedule &&
    promotionState.appliedRules.includes("time")
  ) {
    createUpdateBookingObject.schedule.time = {
      start: {
        hour: promotionState.schedule.time.start.getHours() as Hour,
        minute: promotionState.schedule.time.start.getMinutes() as Minute,
      },
      end: {
        hour: promotionState.schedule.time.end.getHours() as Hour,
        minute: promotionState.schedule.time.end.getMinutes() as Minute,
      },
    };
  }

  const handleCreatePromotion = useCallback(() => {
    if (premiseId) {
      store.dispatch(
        createPremisePromotion({
          companyOrPremiseId: premiseId,
          promotion: createUpdateBookingObject as any,
          applyToScreens: promotionState.screens,
        })
      );
    } else if (companyId) {
      store.dispatch(
        createCompanyPromotion({
          companyOrPremiseId: companyId,
          promotion: createUpdateBookingObject as any,
          applyToScreens: promotionState.screens,
        })
      );
    }

    handleClose();
  }, [companyId, createUpdateBookingObject, premiseId, promotionState.screens]);

  const handleUpdatePromotion = useCallback(() => {
    if (!promotionState.promotionId) return;

    if (premiseId) {
      store.dispatch(
        updatePremisePromotion({
          companyOrPremiseId: premiseId,
          promotionId: promotionState.promotionId,
          promotionData: createUpdateBookingObject,
          applyToScreens: promotionState.screens,
        })
      );
    } else if (companyId) {
      store.dispatch(
        updateCompanyPromotion({
          companyOrPremiseId: companyId,
          promotionId: promotionState.promotionId,
          promotionData: createUpdateBookingObject,
          applyToScreens: promotionState.screens,
        })
      );
    }
    handleClose();
  }, [
    companyId,
    createUpdateBookingObject,
    premiseId,
    promotionState.promotionId,
    promotionState.screens,
  ]);

  const createUpdateAction = useCallback(() => {
    if (promotionState.promotionId) {
      return {
        label: t("customerportal.pages.promotions.update_promotion"),
        onClick: handleUpdatePromotion,
        disabled: false,
      };
    } else {
      return {
        label: t("customerportal.pages.promotions.create_promotion"),
        onClick: handleCreatePromotion,
        disabled: false,
      };
    }
  }, [
    handleCreatePromotion,
    handleUpdatePromotion,
    promotionState.promotionId,
  ]);

  const dialogActions = useCallback(() => {
    const actions: CustomDialogAction[] = [];

    if (activeStep > 0 && activeStep <= 4) {
      actions.push({
        label: t("common.previous"),
        onClick: handleBack,
        disabled: false,
      });
    }
    if (activeStep < 4) {
      actions.push({
        label: t("common.next"),
        onClick: handleNext,
        disabled: !isValidStepClick(activeStep),
      });
    }

    if (activeStep === 4) {
      actions.push(createUpdateAction());
    }

    return actions;
  }, [
    activeStep,
    isValidStepClick,
    createUpdateAction,
    handleBack,
    handleNext,
  ]);

  return (
    <>
      {scope.company && (
        <DefaultDialog
          open={isOpen}
          onClose={handleClose}
          width={isSmallDesktop ? "80%" : "60%"}
          defaultSaveButton={false}
          customActions={dialogActions()}
          title={
            promotionState.promotionId
              ? (t("customerportal.pages.promotions.edit_promotion") as string)
              : (t(
                  "customerportal.pages.promotions.create_promotion"
                ) as string)
          }
        >
          {!isLoading ? (
            <StepperForm
              state={promotionState}
              setState={setPromotionState}
              assets={assets}
              companyId={companyId || ""}
              premiseId={premiseId}
              progressConditions={progressConditions}
              isValidStepClick={isValidStepClick}
              validationRules={{
                minDuration,
                maxDuration,
              }}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              appliedScheduleRules={promotionState.appliedRules}
              onToggleScheduleRuleApplied={handleScheduleRuleApplied}
              formattedState={createUpdateBookingObject}
            />
          ) : (
            <Box
              sx={{
                height: "10rem",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </DefaultDialog>
      )}
    </>
  );
};

export default CreateEditPromotionModal;
