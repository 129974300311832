import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Epic, ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators";
import { CustomerPortalState } from "..";
import {
  IContentChannel,
  IContentOverride,
  IOrganizationUnit,
  IRoom,
  ISignagePlaylist,
  IWeeklyScheduleItem,
  LoadingStatus,
  NendaProduct,
  ScheduleContentRefType,
  ScheduledCommand,
} from "../../../../../types/NendaTypes";
import { IListResponse } from "../../../../../types/RequestTypes";
import { organizationUnitService } from "../../../../http/organizationUnit.service";
import { roomService } from "../../../../http/room.service";
import {
  BatchGetTimelineForScreenAction,
  BatchGetTimelineForScreenSuccessAction,
  GetTimelineForScreenAction,
  SCREEN_ACTIONS,
  UpdateContentOverrideAction,
} from "../../../../types/redux";
import { selectContentChannelById } from "./contentChannelReducer";
import {
  handleError,
  SetNotificationError,
  SetNotificationSuccess,
} from "./notificationReducer";
import { selectSignagePlaylistById } from "./signagePlaylistReducer";

export interface ContentTimelineInterval {
  start: Date;
  end: Date;
  contentId: string;
  contentRefType: ScheduleContentRefType;
  type: string;
}

export interface ScreenState {
  screens: IListResponse<IRoom>;
  timelines: Record<string, ContentTimelineInterval[]>;
  isLoading: boolean;
  createScreensStatus: LoadingStatus;
}

export const initialScreenState: ScreenState = {
  screens: {
    data: [],
    page: 0,
    pageSize: 0,
    filteredCount: 0,
    totalCount: 0,
  },
  timelines: {},
  isLoading: false,
  createScreensStatus: LoadingStatus.IDLE,
};

const screenSlice = createSlice({
  name: "screen",
  initialState: initialScreenState,
  reducers: {
    getScreen: (state, _action: PayloadAction<string>) => {
      state.isLoading = true;
    },
    getScreenSuccess: (state, action: PayloadAction<IRoom>) => {
      state.isLoading = false;
      const index = state.screens.data.findIndex(
        (screen) => screen._id === action.payload._id
      );
      if (index === -1) {
        state.screens.data.push(action.payload);
      } else {
        state.screens.data[index] = action.payload;
      }
    },
    getScreenFailure: (state) => {
      state.isLoading = false;
    },
    getScreens: (state, _action: PayloadAction<string>) => {
      state.isLoading = true;
    },
    getScreensFailure: (state) => {
      state.isLoading = false;
    },
    batchGetScreens: (state, _action: PayloadAction<string[]>) => {
      state.isLoading = true;
    },
    setScreens: (state, action: PayloadAction<IRoom[]>) => {
      const updatedScreens = state.screens.data;

      for (const screen of action.payload) {
        const index = updatedScreens.findIndex((s) => s._id === screen._id);
        if (index === -1) {
          updatedScreens.push(screen);
        } else {
          updatedScreens[index] = screen;
        }
      }
      state.isLoading = false;
      state.screens = {
        ...state.screens,
        data: updatedScreens,
      };
    },
    updateScreen: (state, _action: PayloadAction<Partial<IRoom>>) => {
      state.isLoading = true;
    },
    deleteScreen: (state, _action: PayloadAction<string>) => {
      state.isLoading = true;
    },
    deleteScreenSuccess: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.screens.data = state.screens.data.filter(
        (screen) => screen._id !== action.payload
      );
    },
    deleteScreenFailure: (state) => {
      state.isLoading = false;
    },
    setUpdatedScreen: (state, action: PayloadAction<IRoom>) => {
      state.isLoading = false;
      state.screens = {
        ...state.screens,
        data: state.screens.data.map((screen) =>
          screen?._id === action.payload._id ? action.payload : screen
        ),
      };
    },
    getTimelineForScreen: (
      state,
      _action: PayloadAction<{ id: string; query: GetTimelineForScreenAction }>
    ) => {
      state.isLoading = true;
    },
    batchGetTimelineForScreen: (
      state,
      _action: PayloadAction<BatchGetTimelineForScreenAction>
    ) => {
      state.isLoading = true;
    },
    batchGetTimelinesForScreens: (
      state,
      _action: PayloadAction<BatchGetTimelineForScreenSuccessAction>
    ) => {
      state.isLoading = false;
    },
    setTimelinesForScreen: (
      state,
      action: PayloadAction<{
        timeline: ContentTimelineInterval[];
        screenId: string;
      }>
    ) => {
      state.isLoading = false;
      state.timelines[action.payload.screenId] = action.payload.timeline;
    },
    batchSetTimelinesForScreens: (
      state,
      action: PayloadAction<BatchGetTimelineForScreenSuccessAction>
    ) => {
      state.isLoading = false;
      state.timelines = action.payload.timelines;
    },
    batchSetContentOverride: (
      _state,
      _action: PayloadAction<
        { screenId: string; contentOverride: IContentOverride }[]
      >
    ) => {},
    batchPutWeeklyScheduleForScreens: (
      state,
      _action: PayloadAction<IWeeklyScheduleItem[]>
    ) => {
      state.isLoading = true;
    },
    batchPutWeeklyScheduleForScreensSuccess: (
      state,
      action: PayloadAction<IRoom[]>
    ) => {
      const updatedScreens = [...state.screens.data];
      for (const screen of action.payload) {
        const index = updatedScreens.findIndex((s) => s._id === screen._id);
        if (index !== -1) {
          updatedScreens[index] = screen;
        }
      }

      state.screens.data = updatedScreens;
      state.isLoading = false;
    },
    unsetChannelOverride: (state, _action: PayloadAction<string>) => {
      state.isLoading = true;
    },
    batchUnsetChannelOverride: (_state, _action: PayloadAction<string[]>) => {
      _state.isLoading = true;
    },
    batchUnsetChannelOverrideSuccess: (
      state,
      _action: PayloadAction<IRoom[]>
    ) => {
      const updatedScreens = [...state.screens.data];
      for (const screen of _action.payload) {
        const index = updatedScreens.findIndex((s) => s._id === screen._id);
        if (index !== -1) {
          updatedScreens[index] = screen;
        }
      }
      state.isLoading = false;
      state.screens.data = updatedScreens;
    },
    batchAssignPromotionToScreenState: (
      state,
      action: PayloadAction<{ promotionId: string; screens: string[] }>
    ) => {
      state.screens.data = state.screens.data.map((screen) => {
        // Add promotion to screen if it doesn't exist
        // Remove it if it exists on screens that is not provided
        if (action.payload.screens.includes(screen._id)) {
          if (
            !screen.signage.schedule.promotions.includes(
              action.payload.promotionId
            )
          ) {
            screen.signage.schedule.promotions.push(action.payload.promotionId);
          }
        } else {
          screen.signage.schedule.promotions =
            screen.signage.schedule.promotions.filter(
              (p) => p !== action.payload.promotionId
            );
        }
        return screen;
      });
    },
    batchSetDefaultContentForScreens: (
      state,
      _action: PayloadAction<{
        contentId: string;
        contentRefType: ScheduleContentRefType;
        screens: string[];
      }>
    ) => {
      state.isLoading = true;
    },
    batchSetDefaultContentForScreensSuccess: (
      state,
      _action: PayloadAction<IRoom[]>
    ) => {
      state.isLoading = false;
    },
    addScheduledCommand: (
      state,
      _action: PayloadAction<{
        screenId: string;
        command: ScheduledCommand;
      }>
    ) => {
      state.isLoading = true;
    },
    addScheduledCommandSuccess: (state, _action: PayloadAction<IRoom>) => {
      state.isLoading = false;
    },
    addScheduledCommandFailure: (state) => {
      state.isLoading = false;
    },
  },
});

export function UpdateContentOverride(
  screenId: string,
  data: IContentOverride
): UpdateContentOverrideAction {
  return {
    type: SCREEN_ACTIONS.UPDATE_CONTENT_OVERRIDE,
    screenId,
    data: {
      from: data.from,
      to: data.to,
      content: data.content,
      contentRefType: data.contentRefType,
    },
  };
}

export const selectScreensForPremise = createSelector(
  [
    (state: CustomerPortalState) => state.screen.screens.data,
    (_state: CustomerPortalState, premiseId: string) => premiseId,
  ],
  (screens, premiseId) => screens.filter((s) => s.hotel === premiseId)
);

export const selectScreensForPremises = createSelector(
  [
    (state: CustomerPortalState) => state.screen.screens.data,
    (_state: CustomerPortalState, premiseIds: string[]) => premiseIds,
  ],
  (screens, premiseIds) => screens.filter((s) => premiseIds.includes(s.hotel))
);

export const selectScreensByPremiseAndProduct = createSelector(
  [
    (state: CustomerPortalState) => state.screen.screens.data,
    (_state: CustomerPortalState, premiseId: string) => premiseId,
    (_state: CustomerPortalState, _premiseId: string, product: NendaProduct) =>
      product,
  ],
  (screens, premiseId, product) =>
    screens.filter(
      (s) => s.hotel === premiseId && s.nendaProducts.includes(product)
    )
);

export const selectScreensByProduct = createSelector(
  [
    (state: CustomerPortalState) => state.screen.screens.data,
    (_state: CustomerPortalState, product: NendaProduct) => product,
  ],
  (screens, product) => {
    return screens.filter((s) => s.nendaProducts.includes(product));
  }
);

export const selectTimelines = createSelector(
  [(state: CustomerPortalState) => state.screen.timelines],
  (timelines) => {
    if (Object.keys(timelines).length === 0) {
      return timelines;
    }
    const parsedTimelines = {};

    Object.keys(timelines).forEach((key) => {
      parsedTimelines[key] = timelines[key].map((item) => ({
        ...item,
        start: new Date(item.start),
        end: new Date(item.end),
      }));
    });

    return parsedTimelines;
  }
);

export const selectScreenById = createSelector(
  [
    (state: CustomerPortalState): IRoom[] => state.screen.screens.data,
    (_state: CustomerPortalState, id: string): string => id,
  ],
  (screens, id): IRoom | undefined => screens.find((s): boolean => s._id === id)
);

export const selectScreensByProductAndPremise = createSelector(
  [
    (state: CustomerPortalState) => state.screen.screens.data,
    (_state: CustomerPortalState, product: NendaProduct) => product,
    (
      _state: CustomerPortalState,
      _product: NendaProduct,
      id: IOrganizationUnit["_id"]
    ) => id,
  ],
  (screens, product, id) => {
    return screens.filter(
      (s) => s.nendaProducts.includes(product) && s.hotel === id
    );
  }
);

export const selectScreensByPromotionId = createSelector(
  [
    (state: CustomerPortalState) => state.screen.screens.data,
    (_state: CustomerPortalState, promotionId: string | undefined) =>
      promotionId,
  ],
  (screens, promotionId) => {
    if (!promotionId) return [];
    return screens.filter((s) =>
      s.signage.schedule.promotions.includes(promotionId)
    );
  }
);

export const getIsLoading = (state: CustomerPortalState): boolean => {
  return state.screen.isLoading;
};

export const selectDefaultContentForScreen = createSelector(
  [
    (state: CustomerPortalState) => state.screen.screens.data,
    (_state: CustomerPortalState, screenId: string) => screenId,
    (state: CustomerPortalState) => state,
  ],
  (screens, screenId, state) => {
    const screen = screens.find((s) => s._id === screenId);
    if (!screen) return undefined;
    if (!screen.signage.config?.defaultContent) return undefined;

    const defaultContentRefType: ScheduleContentRefType =
      screen?.signage.config?.defaultContentRefType;
    const defaultContentId = screen?.signage.config?.defaultContent;

    const defaultContent: IContentChannel | ISignagePlaylist | undefined =
      defaultContentRefType === ScheduleContentRefType.CONTENT_CHANNEL
        ? selectContentChannelById(state, defaultContentId)
        : selectSignagePlaylistById(state, defaultContentId);

    return defaultContent;
  }
);

// Epics

const getScreen$: Epic = (action$) => {
  return action$.pipe(
    filter(screenSlice.actions.getScreen.match),
    switchMap((a) => {
      return roomService.getRoom(a.payload).pipe(
        map((screen: IRoom) => {
          return screenSlice.actions.getScreenSuccess(screen);
        }),
        catchError(() => {
          return [
            getScreenFailure(),
            SetNotificationError("Failed to get screen"),
          ];
        })
      );
    })
  );
};

const getScreens$: Epic = (action$) => {
  return action$.pipe(
    filter(screenSlice.actions.getScreens.match),
    switchMap((a) => {
      return organizationUnitService.getRoomsForHotel(a.payload).pipe(
        map((screens: IListResponse<IRoom>) => {
          return setScreens(screens.data);
        }),
        catchError(() => {
          return [
            getScreensFailure(),
            SetNotificationError("Failed to get screens"),
          ];
        })
      );
    })
  );
};

const batchGetScreens$: Epic = (action$) => {
  return action$.pipe(
    filter(screenSlice.actions.batchGetScreens.match),
    switchMap((a) => {
      return organizationUnitService.getRoomsForHotels(a.payload).pipe(
        map((screens: IRoom[]) => {
          return setScreens(screens);
        }),
        catchError(handleError)
      );
    })
  );
};

const batchUpdateContentOverride$: Epic = (action$) => {
  return action$.pipe(
    filter(screenSlice.actions.batchSetContentOverride.match),
    switchMap((a: any) => {
      return roomService.batchSetContentOverride(a.payload).pipe(
        mergeMap((response: IRoom[]) => {
          return [
            getScreens(response[0].hotel),
            SetNotificationSuccess("Screens have been overridden"),
          ];
        }),
        catchError(handleError)
      );
    })
  );
};

const updateContentOverride$: Epic = (action$) => {
  return action$.pipe(
    ofType(SCREEN_ACTIONS.UPDATE_CONTENT_OVERRIDE),
    switchMap((a: UpdateContentOverrideAction) => {
      return roomService.setContentOverride(a.screenId, a.data).pipe(
        mergeMap((response: IRoom) => {
          return [
            getScreens(response.hotel),
            setUpdatedScreen(response),
            SetNotificationSuccess("Screen has been overridden"),
          ];
        }),
        catchError(handleError)
      );
    })
  );
};
const batchGetTimelineForScreen$: Epic = (action$) => {
  return action$.pipe(
    filter(screenSlice.actions.batchGetTimelineForScreen.match),
    switchMap(({ payload }) => {
      return roomService
        .batchGetTimeline(payload.ids, {
          from: payload.query?.from,
          to: payload.query?.to,
        })
        .pipe(
          map((timelines) => {
            return batchSetTimelinesForScreens({ timelines });
          }),
          catchError(handleError)
        );
    })
  );
};

const updateScreen$: Epic = (action$) => {
  return action$.pipe(
    filter(screenSlice.actions.updateScreen.match),
    switchMap((a) => {
      return organizationUnitService.updateRoom(a.payload._id, a.payload).pipe(
        mergeMap((response: IRoom) => {
          return [
            setUpdatedScreen(response),
            SetNotificationSuccess("Screen updated"),
          ];
        }),
        catchError(handleError)
      );
    })
  );
};

const deleteScreen$: Epic = (action$) => {
  return action$.pipe(
    filter(screenSlice.actions.deleteScreen.match),
    switchMap((a) => {
      return organizationUnitService.deleteRoom(a.payload).pipe(
        map((room) => {
          return deleteScreenSuccess(room._id);
        }),
        catchError((error) => of([handleError(error), deleteScreenFailure()]))
      );
    })
  );
};

const batchPutWeeklyScheduleForScreens$: Epic = (action$) => {
  return action$.pipe(
    filter(screenSlice.actions.batchPutWeeklyScheduleForScreens.match),
    switchMap((a) => {
      return roomService.batchPutWeeklyScheduleForScreens(a.payload).pipe(
        mergeMap((response: IRoom[]) => {
          return [
            batchPutWeeklyScheduleForScreensSuccess(response),
            SetNotificationSuccess("Schedule updated"),
          ];
        }),
        catchError(handleError)
      );
    })
  );
};

const batchSetDefaultContentForScreens$: Epic = (action$) => {
  return action$.pipe(
    filter(screenSlice.actions.batchSetDefaultContentForScreens.match),
    switchMap((a) => {
      return roomService
        .batchSetDefaultContentForScreens({
          contentId: a.payload.contentId,
          contentRefType: a.payload.contentRefType,
          screenIds: a.payload.screens,
        })
        .pipe(
          mergeMap((response: IRoom[]) => {
            return [
              batchSetDefaultContentForScreensSuccess(response),
              SetNotificationSuccess("Default channel has been set"),
            ];
          }),
          catchError(handleError)
        );
    })
  );
};

const unsetChannelOverride$: Epic = (action$) => {
  return action$.pipe(
    filter(screenSlice.actions.unsetChannelOverride.match),
    switchMap((a) => {
      return roomService.unsetContentOverride(a.payload).pipe(
        mergeMap((response: IRoom) => {
          return [
            setUpdatedScreen(response),
            SetNotificationSuccess("Content override removed"),
          ];
        }),
        catchError(handleError)
      );
    })
  );
};

const batchUnsetChannelOverride$: Epic = (action$) => {
  return action$.pipe(
    filter(screenSlice.actions.batchUnsetChannelOverride.match),
    switchMap((a) => {
      return roomService
        .batchUnsetContentOverride({ screenIds: a.payload })
        .pipe(
          mergeMap((response: IRoom[]) => {
            return [
              batchUnsetChannelOverrideSuccess(response),
              SetNotificationSuccess("All content overrides removed"),
            ];
          }),
          catchError(handleError)
        );
    })
  );
};

const addScheduledCommand$: Epic = (action$) => {
  return action$.pipe(
    filter(screenSlice.actions.addScheduledCommand.match),
    switchMap((a) => {
      return roomService
        .addScheduledCommand(a.payload.screenId, a.payload.command)
        .pipe(
          mergeMap((response: IRoom) => {
            return [
              addScheduledCommandSuccess(response),
              SetNotificationSuccess("Command sent!"),
            ];
          }),
          catchError(() => {
            return [
              addScheduledCommandFailure(),
              SetNotificationError("Failed to send command"),
            ];
          })
        );
    })
  );
};

export const {
  getScreen,
  getScreenSuccess,
  getScreenFailure,
  setScreens,
  getScreens,
  getScreensFailure,
  batchGetScreens,
  updateScreen,
  setUpdatedScreen,
  batchGetTimelineForScreen,
  setTimelinesForScreen,
  getTimelineForScreen,
  batchSetTimelinesForScreens,
  batchSetContentOverride,
  batchPutWeeklyScheduleForScreens,
  batchPutWeeklyScheduleForScreensSuccess,
  unsetChannelOverride,
  batchUnsetChannelOverride,
  batchUnsetChannelOverrideSuccess,
  batchAssignPromotionToScreenState,
  batchSetDefaultContentForScreens,
  batchSetDefaultContentForScreensSuccess,
  deleteScreen,
  deleteScreenSuccess,
  deleteScreenFailure,
  addScheduledCommand,
  addScheduledCommandSuccess,
  addScheduledCommandFailure,
} = screenSlice.actions;

export const screenEpics = [
  getScreen$,
  getScreens$,
  batchGetTimelineForScreen$,
  batchGetScreens$,
  updateScreen$,
  updateContentOverride$,
  batchUpdateContentOverride$,
  batchPutWeeklyScheduleForScreens$,
  unsetChannelOverride$,
  batchUnsetChannelOverride$,
  batchSetDefaultContentForScreens$,
  deleteScreen$,
  addScheduledCommand$,
];

export default screenSlice.reducer;
