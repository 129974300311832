import { errorMessageTemplate } from "@client/utils/string";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, mergeMap, switchMap } from "rxjs/operators";
import { CustomerPortalState } from "..";
import {
  Company,
  IOrganizationUnit,
  LoadingStatus,
  NendaProduct,
  RoomStatus,
} from "../../../../../types/NendaTypes";
import { companyService } from "../../../../http/companyService";
import { organizationUnitService } from "../../../../http/organizationUnit.service";
import { SetCompany } from "./companyReducer";
import { SetNotificationError } from "./notificationReducer";
import { SetPremise } from "./organizationUnitReducer";
import { setScreens } from "./screenReducer";

export type CreateRoomsRangeParameters = {
  from: number;
  to: number;
  roomType: string;
  errors: RoomError;
};

export type RoomError = { from: string; to: string; roomType: string };

export type CreateRoomsRangeParametersWithError = CreateRoomsRangeParameters & {
  errors: RoomError;
};

export type CreateRoomSetsProps = {
  roomNumbers: number[];
  roomType: string;
  status: RoomStatus;
  nendaProducts: NendaProduct[];
};

export interface CustomerSetupAction {
  company: Company | undefined;
  premise: Partial<IOrganizationUnit & { password: string }>;
  rooms?: CreateRoomSetsProps[] | [];
}

export type GeneralFormErrors = {
  premises: string;
  premiseName: string;
  company: string;
  premiseGroup: string;
  password: string;
  products: string;
};

export interface CustomerSetupState {
  companyLoadingStatus: LoadingStatus;
  premiseLoadingStatus: LoadingStatus;
  roomsLoadingStatus: LoadingStatus;
}

export const initialCustomerSetupState: CustomerSetupState = {
  companyLoadingStatus: LoadingStatus.IDLE,
  premiseLoadingStatus: LoadingStatus.IDLE,
  roomsLoadingStatus: LoadingStatus.IDLE,
};

const customerSetupSlice = createSlice({
  name: "customerSetup",
  initialState: initialCustomerSetupState,
  reducers: {
    createCustomerSetup: (
      state,
      _action: PayloadAction<CustomerSetupAction>
    ) => {
      const company = _action.payload.company;
      const shouldCreateCompany = company && company.name && !company._id;
      const companyLoadingStatus = shouldCreateCompany
        ? LoadingStatus.LOADING
        : LoadingStatus.SUCCEEDED;
      state.companyLoadingStatus = companyLoadingStatus;
    },
    createCompanySetup: (state, _action: PayloadAction<Company>) => {
      state.companyLoadingStatus = LoadingStatus.LOADING;
    },
    createCompanySetupSuccess: (state) => {
      state.companyLoadingStatus = LoadingStatus.SUCCEEDED;
    },
    createCompanySetupFailure: (state) => {
      state.companyLoadingStatus = LoadingStatus.FAILED;
    },
    createPremiseSetup: (
      state,
      _action: PayloadAction<{
        premise: Partial<IOrganizationUnit>;
        roomSets: CreateRoomSetsProps[] | undefined;
      }>
    ) => {
      state.premiseLoadingStatus = LoadingStatus.LOADING;
    },
    createPremiseSetupSuccess: (state) => {
      state.premiseLoadingStatus = LoadingStatus.SUCCEEDED;
    },
    createPremiseSetupFailure: (state) => {
      state.premiseLoadingStatus = LoadingStatus.FAILED;
    },
    createRoomsSetup: (
      state,
      _action: PayloadAction<{
        hotelChainId: string;
        hotelId: string;
        roomSets: CreateRoomSetsProps[];
      }>
    ) => {
      state.roomsLoadingStatus = LoadingStatus.LOADING;
    },
    createRoomsSetupSuccess: (state) => {
      state.roomsLoadingStatus = LoadingStatus.SUCCEEDED;
    },
    createRoomsSetupFailure: (state) => {
      state.roomsLoadingStatus = LoadingStatus.FAILED;
    },
    setRoomsLoadingStatus: (state, action: PayloadAction<LoadingStatus>) => {
      state.roomsLoadingStatus = action.payload;
    },
    resetLoadingStatus: (state) => {
      state.companyLoadingStatus = LoadingStatus.IDLE;
      state.premiseLoadingStatus = LoadingStatus.IDLE;
      state.roomsLoadingStatus = LoadingStatus.IDLE;
    },
  },
});

//Selectors
export const selectCustomerSetupState = (state: CustomerPortalState) =>
  state.customerSetup;

export const selectCustomerSetupIsLoading = (state: CustomerPortalState) => {
  const customerSetupState = selectCustomerSetupState(state);
  return (
    customerSetupState.companyLoadingStatus === LoadingStatus.LOADING ||
    customerSetupState.premiseLoadingStatus === LoadingStatus.LOADING ||
    customerSetupState.roomsLoadingStatus === LoadingStatus.LOADING
  );
};

export const selectCompanyLoadingStatus = createSelector(
  selectCustomerSetupState,
  (state): LoadingStatus => state.companyLoadingStatus
);

export const selectPremiseLoadingStatus = createSelector(
  selectCustomerSetupState,
  (state): LoadingStatus => state.premiseLoadingStatus
);

export const selectRoomsLoadingStatus = createSelector(
  selectCustomerSetupState,
  (state): LoadingStatus => state.roomsLoadingStatus
);

//Epics
const createCustomerSetupEpic$: Epic = (action$) =>
  action$.pipe(
    filter(customerSetupSlice.actions.createCustomerSetup.match),
    switchMap((action) => {
      const { company, premise, rooms } = action.payload;
      const shouldCreateCompany = company && company.name && !company._id;

      if (shouldCreateCompany) {
        return companyService.createCompany(company).pipe(
          mergeMap((response: Company) => {
            const premiseWithNewCompanyId = {
              ...premise,
              company: response._id,
            };
            return of(
              SetCompany(response),
              createCompanySetupSuccess(),
              createPremiseSetup({
                premise: premiseWithNewCompanyId,
                roomSets: rooms,
              })
            );
          }),
          catchError((error) => {
            console.error("CreateCompany error:", error);
            return of(
              SetNotificationError(`Error Creating Company: ${error.message}`),
              createCompanySetupFailure()
            );
          })
        );
      } else {
        return of(
          createPremiseSetup({
            premise: { ...premise, company: company?._id },
            roomSets: rooms,
          })
        );
      }
    })
  );

const createPremiseSetupEpic$: Epic = (action$) =>
  action$.pipe(
    filter(customerSetupSlice.actions.createPremiseSetup.match),
    switchMap((action) => {
      const { premise, roomSets } = action.payload;
      return organizationUnitService.createOrganizationUnit(premise).pipe(
        mergeMap((response: IOrganizationUnit) => {
          const shouldCreateRooms = roomSets && roomSets.length > 0;

          if (shouldCreateRooms) {
            return from([
              SetPremise(response),
              createPremiseSetupSuccess(),
              createRoomsSetup({
                hotelChainId: response.parent,
                hotelId: response._id,
                roomSets,
              }),
            ]);
          } else {
            return from([SetPremise(response), createPremiseSetupSuccess()]);
          }
        }),
        catchError((error) => {
          console.error("CreatePremise error:", error);
          return of(
            createPremiseSetupFailure(),
            SetNotificationError(`Error Creating Premise: ${error.message}`)
          );
        })
      );
    })
  );

const createRoomsSetupEpic$: Epic = (action$) =>
  action$.pipe(
    filter(customerSetupSlice.actions.createRoomsSetup.match),
    switchMap((action) => {
      const { hotelChainId, hotelId, roomSets } = action.payload;
      return organizationUnitService
        .createRoomRanges({
          roomSets,
          hotelChainId,
          hotelId,
        })
        .pipe(
          mergeMap((response) => {
            return of(
              createRoomsSetupSuccess(response.report.rooms),
              setScreens(response.report.rooms)
            );
          }),
          catchError((error) => {
            const createdRooms = error.response.report.createdRoomsStatus;
            const hasCreatedRooms = createdRooms.length > 0;
            const createdRoomsMessage = hasCreatedRooms
              ? `Successfully created rooms (${createdRooms
                  .map((r) => r.roomNumber)
                  .join(", ")})`
              : "";

            console.error("CreateRooms Report:", error.response.report);

            const errorMessage = () => {
              switch (error.status) {
                case 409:
                  return errorMessageTemplate(
                    `Room number ${error.response.report.currentRoom.roomNumber} already exists.`,
                    error.status
                  );
                case 500:
                default:
                  return errorMessageTemplate(
                    `There was a problem while creating room: ${error.response.report.currentRoom.roomNumber}`,
                    error.status
                  );
              }
            };

            return of(
              createRoomsSetupFailure(),
              SetNotificationError(errorMessage())
            );
          })
        );
    })
  );

export const {
  createCustomerSetup,
  createCompanySetup,
  createCompanySetupFailure,
  createCompanySetupSuccess,
  createPremiseSetup,
  createPremiseSetupFailure,
  createPremiseSetupSuccess,
  createRoomsSetup,
  createRoomsSetupFailure,
  createRoomsSetupSuccess,
  setRoomsLoadingStatus,
  resetLoadingStatus,
} = customerSetupSlice.actions;

export const customerSetupEpics = [
  createCustomerSetupEpic$,
  createPremiseSetupEpic$,
  createRoomsSetupEpic$,
];
export default customerSetupSlice.reducer;
